// types
import { SetState } from "@/types/common";
// hooks
import { useCustomCancelModal } from "@/hooks";

const CustomCancelButton = ({
  setShowParcelNotFitModal
}: {
  setShowParcelNotFitModal: SetState<boolean>;
}) => {
  useCustomCancelModal(() => {
    setShowParcelNotFitModal(false);
  }, []);

  return null;
};

export default CustomCancelButton;

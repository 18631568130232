// libs
import * as yup from "yup";
// others
import CONSTANTS from "@/constants";
import { formatPhoneNo } from "@/utils";

const { RECEIVER_PHONE_NO, RECEIVER_NAME, CABINET_SIZE } =
  CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;
CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;
const { PHONE_NO_MIN_LENGTH } = CONSTANTS.CODE_LENGTH;

export default ({
  localeDataSource,
  phoneNumber
}: {
  localeDataSource: Record<string, string>;
  phoneNumber: string | null;
}) =>
  yup.object().shape({
    [RECEIVER_PHONE_NO]: yup
      .string()
      .required(localeDataSource.COMMON_MSG_E_001)
      .min(PHONE_NO_MIN_LENGTH, localeDataSource.COMMON_MSG_E_004)
      .test(
        "not-allow-self-sending",
        localeDataSource.CABINET_ASSIGN_MSG_E_003,
        (phoneNo) => formatPhoneNo(phoneNo as string) !== phoneNumber
      ),
    [RECEIVER_NAME]: yup
      .string()
      .required(localeDataSource.CABINET_ASSIGN_MSG_E_002),
    [CABINET_SIZE]: yup
      .string()
      .required(localeDataSource.CABINET_ASSIGN_MSG_E_001)
  });

import ROUTERS from "./routers";
import SCREEN_NAMES from "./screenNames";
import ASSET_LINKS from "./assetLinks";
import COLLECTIONS from "./collections";
import { COLLECTION_FIELD_NAMES, SCREEN_FIELD_NAMES } from "./fieldNames";
import COLORS from "./colors";
import FUNCTION_NAMES from "./functionNames";
import MAPPINGS from "./mappings";
import CAMERA_CONFIG from "./cameraConfig";

const VI_VN = "vi";
const EN_US = "en";

const CONSTANTS = {
  LOCAL_STORAGE_KEY: {
    LOCALE: "locale"
  },
  LOCALE: { VI_VN, EN_US },
  ROUTERS,
  SCREEN_NAMES,
  ASSET_LINKS,
  PHONE_NO_FORMAT: "(+##) ### ### ###",
  RESEND_INTERVAL: 120,
  SCREEN_FIELD_NAMES,
  COLLECTIONS,
  COLLECTION_FIELD_NAMES,
  PHONE_NO_PREFIX: {
    [VI_VN]: "+84"
  },
  COLORS,
  CODE_LENGTH: {
    VERIFICATION_CODE_CELL_COUNT: 6,
    PHONE_NO_MIN_LENGTH: 10,
    PHONE_NO_MAX_LENGTH: 12
  },
  FUNCTION_NAMES,
  MAPPINGS,
  CAMERA_CONFIG
};

export default CONSTANTS;

// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const CopyRightSection = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <p className={styles.copyRightSectionWrapper}>
      {localeDataSource["Home.copyright"]}
    </p>
  );
};

export default CopyRightSection;

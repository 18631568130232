// libs
import { Button } from "antd-mobile";
// hooks
import { useLocaleContext, useRouter } from "@/hooks";
// others
import styles from "./styles.module.less";

const ChangePhoneNo = () => {
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();

  return (
    <div className={styles.changePhoneNoWrapper}>
      <Button
        className={styles.changePhoneNoWrapperInner}
        onClick={() => router.goBack()}
      >
        <p>{localeDataSource["SignInOTP.change.phone.no"]}</p>
      </Button>
    </div>
  );
};

export default ChangePhoneNo;

// types
import { SendingStep } from "@/types/screens/SendingProcess";
// components
import CabinetNo from "./mains/CabinetNo";
import OperationGuideHeading from "./mains/OperationGuideHeading";
import OperationGuideSteps from "./mains/OperationGuideSteps";
import CloseDoorWarning from "./mains/CloseDoorWarning";
// others
import styles from "./styles.module.less";

const OperationProcess = ({
  headingTitle,
  implementationProcess,
  messageWarning
}: {
  headingTitle: string;
  implementationProcess: SendingStep[];
  messageWarning: string;
}) => (
  <div className={styles.operationProcessWrapper}>
    <CabinetNo />
    <OperationGuideHeading {...{ headingTitle }} />
    <OperationGuideSteps {...{ implementationProcess }} />
    <CloseDoorWarning {...{ messageWarning }} />
  </div>
);

export default OperationProcess;

// components
import MatIcon from "@/components/MatIcon";
// others
import CONSTANTS from "@/constants";

const ConfirmCancelIcon = () => (
  <MatIcon
    name="mdi-information"
    size="60px"
    color={CONSTANTS.COLORS.AZURE_RADIANCE}
  />
);

export default ConfirmCancelIcon;

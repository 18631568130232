// libs
import { Button } from "antd-mobile";
import { useFormikContext } from "formik";
import { Link } from "react-router-dom";
// types
import { SignInValues } from "@/types/screens/SignIn";
// hooks
import { useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { SWITCH_PREFIX } = CONSTANTS.ROUTERS;
const { PHONE_NO_PREFIX } = CONSTANTS.SCREEN_FIELD_NAMES.SIGN_IN;

const PhoneNoPrefix = () => {
  const { values } = useFormikContext<SignInValues>();
  const router = useRouter();

  return (
    <div className={styles.phoneNoPrefixWrapper}>
      <Link
        to={SWITCH_PREFIX}
        state={{
          background: router.location,
          [PHONE_NO_PREFIX]: values[PHONE_NO_PREFIX]
        }}
      >
        <Button
          className={styles.phoneNoPrefixWrapperInner}
          shape="rectangular"
        >
          {values[PHONE_NO_PREFIX]}
        </Button>
      </Link>
    </div>
  );
};

export default PhoneNoPrefix;

// components
import MatIcon from "@/components/MatIcon";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const SignOutIcon = () => (
  <div className={styles.iconWrapper}>
    <MatIcon
      name="mdi-logout"
      size="60px"
      color={CONSTANTS.COLORS.AZURE_RADIANCE}
    />
  </div>
);

export default SignOutIcon;

// libs
import { Navigate } from "react-router-dom";
import { PropsWithChildren } from "react";
// hooks
import { useAuthorized } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { HOME } = CONSTANTS.ROUTERS;

const MainPreLoad = ({ children }: PropsWithChildren) => {
  const { notAuthenticatedUser } = useAuthorized();

  if (notAuthenticatedUser) return <Navigate to={HOME} />;

  return <>{children}</>;
};

export default MainPreLoad;

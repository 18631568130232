// libs
import { useState, useEffect } from "react";
// types
import { Locker } from "@/types/firestore/lockers";
// hooks
import useLocaleContext from "./useLocaleContext";
import useRouter from "./useRouter";
// others
import { LockerOperation } from "@/serverless";
import { showToastMessage } from "@/utils";

const useGetLocker = ({ lockerId }: { lockerId: string }) => {
  const [lockerInfo, setLockerInfo] = useState<Locker>({} as Locker);
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();

  useEffect(() => {
    if (!lockerId) return;

    (async () => {
      const lockerOpt = new LockerOperation({ lockerId });
      const { data: locker, exists: hasLocker } = await lockerOpt.getLocker();

      if (!hasLocker) {
        showToastMessage(localeDataSource.COMMON_MSG_E_003);
        router.goBack();
        return;
      }

      setLockerInfo(locker);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockerId]);

  return { lockerInfo };
};

export default useGetLocker;

// libs
import { useEffect } from "react";
import { useFormikContext } from "formik";
// types
import { SignInValues } from "@/types/screens/SignIn";
// hooks
import { useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { PHONE_NO_PREFIX } = CONSTANTS.SCREEN_FIELD_NAMES.SIGN_IN;

const SwitchPrefixByRouter = () => {
  const { setFieldValue } = useFormikContext<SignInValues>();
  const router = useRouter();
  const { phoneNoPrefix } = router.state || {};

  useEffect(() => {
    if (phoneNoPrefix) setFieldValue(PHONE_NO_PREFIX, phoneNoPrefix);
  }, [phoneNoPrefix, setFieldValue]);

  return null;
};

export default SwitchPrefixByRouter;

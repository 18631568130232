// types
import { ChildRouteStack } from "@/types/route";
// components
import SwitchLocale from "@/screens/SwitchLocale";
import SignOut from "@/screens/SignOut";
import SwitchPrefix from "@/screens/SwitchPrefix";
import SignOutIcon from "@/screens/SignOut/mains/SignOutIcon";
// others
import CONSTANTS from "@/constants";

const childStackScreens: ChildRouteStack[] = [
  {
    path: CONSTANTS.ROUTERS.SWITCH_LOCALE,
    name: CONSTANTS.SCREEN_NAMES.SWITCH_LOCALE,
    element: SwitchLocale
  },
  {
    path: CONSTANTS.ROUTERS.SIGN_OUT,
    name: CONSTANTS.SCREEN_NAMES.SIGN_OUT,
    element: SignOut,
    title: <SignOutIcon />
  },
  {
    path: CONSTANTS.ROUTERS.SWITCH_PREFIX,
    name: CONSTANTS.SCREEN_NAMES.SWITCH_PREFIX,
    element: SwitchPrefix
  }
];

export default childStackScreens;

// hooks
import { useModalContext } from "@/hooks";
// components
import ModalBody from "../../components/ModalBody";
import ModalFooter from "../../components/ModalFooter";
// others
import styles from "./styles.module.less";

const ModalContent = () => {
  const { showFooter } = useModalContext();

  return (
    <div className={styles.modalContentWrapper}>
      <ModalBody />
      {showFooter && <ModalFooter />}
    </div>
  );
};

export default ModalContent;

// types
import { SignInValues } from "@/types/screens/SignIn";
// others
import CONSTANTS from "@/constants";

const { PHONE_NO_PREFIX, PHONE_NO } = CONSTANTS.SCREEN_FIELD_NAMES.SIGN_IN;

const signInFormData = {
  initialValues: {
    [PHONE_NO_PREFIX]: CONSTANTS.PHONE_NO_PREFIX.vi,
    [PHONE_NO]: ""
  } as SignInValues
};

export default signInFormData;

// libs
import { useEffect, useState } from "react";
// hooks
import useLocaleContext from "./useLocaleContext";
import useRouter from "./useRouter";
// others
import { CabinetOperation } from "@/serverless";
import { showToastMessage } from "@/utils";

const useWaitDoorClose = ({
  cabinetNo,
  lockerId
}: {
  cabinetNo: string;
  lockerId: string;
}) => {
  const [isClosed, setIsClosed] = useState(false);
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();

  useEffect(() => {
    if (!lockerId || !cabinetNo) return;
    (async () => {
      try {
        const cabinetOpt = new CabinetOperation({ lockerId, cabinetNo });
        await cabinetOpt.waitDoorClose();

        setIsClosed(true);
      } catch {
        showToastMessage(localeDataSource.COMMON_MSG_E_005);
        router.goHome();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockerId, cabinetNo]);

  return { isClosed };
};

export default useWaitDoorClose;

// libs
import { useFormikContext } from "formik";
// types
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
// hooks
import { useLocaleContext } from "@/hooks";
// components
import { Input } from "antd-mobile";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { RENTAL_NOTE } = CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;

const RentalNote = () => {
  const { localeDataSource } = useLocaleContext();
  const { values, handleChange } = useFormikContext<CabinetAssignValues>();

  return (
    <div className={styles.rentalNoteWrapper}>
      <Input
        placeholder={localeDataSource["CabinetAssign.rental.note.placeholder"]}
        className={styles.rentalNote}
        value={values[RENTAL_NOTE]}
        onChange={handleChange(RENTAL_NOTE)}
      />
    </div>
  );
};

export default RentalNote;

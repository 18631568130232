// libs
import { Routes, Route } from "react-router-dom";
// hooks
import { useRouter } from "@/hooks";
// ghosts
import ForceRedirectHome from "@/ghosts/ForceRedirectHome";
// components
import AppRoutes from "./AppRoutes";
import ChildRoutes from "./ChildRoutes";
import ContainerPreload from "@/components/ContainerPreload";

const ContainerRoutes = () => {
  const router = useRouter();

  return (
    <>
      <Routes location={router.location}>
        <Route
          path="*"
          element={
            <ContainerPreload>
              <AppRoutes />
              <ChildRoutes />
            </ContainerPreload>
          }
        />
      </Routes>
      <ForceRedirectHome />
    </>
  );
};

export default ContainerRoutes;

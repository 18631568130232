// libs
import { useEffect } from "react";
// hooks
import { useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { CABINET_ASSIGN } = CONSTANTS.ROUTERS;

const RedirectOperation = ({
  triggerRedirect
}: {
  triggerRedirect: number;
}) => {
  const router = useRouter();
  const { lockerInfo } = router.state || {};

  const redirectAuthHome = () => {
    router.goHome();
  };

  const redirectCabinetAssign = () => {
    router.replace(CABINET_ASSIGN, { state: { lockerInfo } });
  };

  useEffect(() => {
    if (!triggerRedirect) return;

    if (!lockerInfo) {
      redirectAuthHome();
      return;
    }

    redirectCabinetAssign();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRedirect]);

  return null;
};

export default RedirectOperation;

// libs
import { useFormikContext } from "formik";
// types
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
// hooks
import useMasterDataContext from "./useMasterDataContext";
import useLocaleContext from "./useLocaleContext";
import useAuthContext from "./useAuthContext";
// others
import CONSTANTS from "@/constants";
import { formatSI } from "@/utils";

const {
  rentalOperationMapping,
  RENTAL_OPERATION: { SENDING }
} = CONSTANTS.MAPPINGS.RENTAL_OPERATION;
const { RECEIVER_NAME, RENTAL_NOTE } = CONSTANTS.COLLECTION_FIELD_NAMES.RENTALS;

const useDefaultRentalNote = () => {
  const { values } = useFormikContext<CabinetAssignValues>();
  const { rentalNoteMaster } = useMasterDataContext();
  const { locale } = useLocaleContext();
  const { user } = useAuthContext();
  const { displayName: senderName } = user || {};

  return {
    defaultRentalNote:
      values[RENTAL_NOTE] ||
      formatSI(rentalNoteMaster[rentalOperationMapping[SENDING]][locale], {
        senderName,
        receiverName: values[RECEIVER_NAME]
      })
  };
};

export default useDefaultRentalNote;

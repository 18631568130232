// components
import ImageWithSkeleton from "@/components/ImageWithSkeleton";
// others
import styles from "./styles.module.less";

const GooglePlaySection = () => (
  <div className={styles.googlePlaySectionWrapper}>
    <a target="blank" href="https://play.google.com/store/apps">
      <ImageWithSkeleton
        src="/assets/images/Home/home_google_play.png"
        alt="google-play-section"
        height={60}
      />
    </a>
  </div>
);

export default GooglePlaySection;

// types
import { SetState } from "@/types/common";
// hooks
import { useLocaleContext, useRouter } from "@/hooks";
// components
import NextStepButton from "@/components/NextStepButton";
// others
import styles from "./styles.module.less";

const ScanningCancelButton = ({
  setScanned
}: {
  setScanned: SetState<boolean>;
}) => {
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();

  return (
    <NextStepButton
      title={localeDataSource["BarcodeScanner.scanning.cancel.button"]}
      customStyle={styles.scanningCancelButtonWrapper}
      onClick={() => {
        setScanned(false);
        router.goBack();
      }}
    />
  );
};
export default ScanningCancelButton;

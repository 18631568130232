// libs
import { DependencyList, useEffect } from "react";
// types
import { CallbackVoid } from "@/types/common";
// hooks
import useModalContext from "./useModalContext";

const useCustomConfirmModal = (
  onOk: CallbackVoid,
  deps: DependencyList = []
) => {
  const { setOnOk } = useModalContext();

  useEffect(() => {
    setOnOk(() => onOk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return null;
};

export default useCustomConfirmModal;

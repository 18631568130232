// libs
import { useState } from "react";
// hooks
import useLocaleContext from "./useLocaleContext";
import useRouter from "./useRouter";
// others
import { validateBarcode, showToastMessage } from "@/utils";

const useBarcodeScanner = () => {
  const [scanned, setScanned] = useState<boolean>(false);
  const { localeDataSource } = useLocaleContext();
  const [barcodeInfo, setBarcodeInfo] = useState<Record<string, string>>({});
  const router = useRouter();

  const handleBarcodeScanned = (code: string) => {
    const parseBarInfo = validateBarcode(code);

    if (!parseBarInfo) {
      showToastMessage(localeDataSource.COMMON_MSG_E_003);
      router.goBack();
      return;
    }

    setScanned(false);
    setBarcodeInfo(parseBarInfo);
  };

  return { scanned, setScanned, handleBarcodeScanned, barcodeInfo };
};

export default useBarcodeScanner;

// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const SelectSizeGuide = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.selectSizeGuideWrapper}>
      {localeDataSource["CabinetAssign.select.size.guide"]}
    </div>
  );
};

export default SelectSizeGuide;

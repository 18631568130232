// libs
import { PasscodeInput } from "antd-mobile";
// types
import { SetState } from "@/types/common";
// others
import styles from "./styles.module.less";

const VerificationCode = ({
  verificationCode,
  setVerificationCode,
  setTriggerSignIn
}: {
  verificationCode: string;
  setVerificationCode: SetState<string>;
  setTriggerSignIn: SetState<number>;
}) => (
  <div className={styles.verificationCodeWrapper}>
    <PasscodeInput
      plain
      seperated
      value={verificationCode}
      onChange={setVerificationCode}
      className={styles.verificationCodeWrapperInner}
      onFill={() => setTriggerSignIn(new Date().getTime())}
    />
  </div>
);

export default VerificationCode;

// libs
import { useEffect } from "react";
// types
import { Cabinet } from "@/types/firestore/cabinets";
import { CabinetSize, CabinetSizesMap } from "@/types/screens/CabinetAssign";
import { SetState } from "@/types/common";
import { CabinetSizeMaster } from "@/types/firestore/masters";
// hooks
import { useGetCabinets, useMasterDataContext } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const {
  cabinetStatusMapping,
  CABINET_STATUS: { VACANT }
} = CONSTANTS.MAPPINGS.CABINET_STATUS;
const { CABINET_STATUS, CABINET_SIZE } =
  CONSTANTS.COLLECTION_FIELD_NAMES.CABINETS;

const generateCabinetSizes = ({
  cabinets,
  cabinetSizeMaster
}: {
  cabinets: Cabinet[];
  cabinetSizeMaster: CabinetSizeMaster;
}) => {
  const { sizesMap: cabinetSizesMap, sizesSet: cabinetSizesSet } =
    cabinets.reduce(
      ({ sizesMap, sizesSet }, thisSize) => {
        const cabinetStatus = thisSize[CABINET_STATUS];
        const cabinetSize = thisSize[CABINET_SIZE];
        const currentVacancyQty = sizesMap[cabinetSize]?.vacancyQty || 0;

        return {
          sizesMap: {
            ...sizesMap,
            [cabinetSize]: {
              cabinetSize,
              ...cabinetSizeMaster[cabinetSize],
              vacancyQty:
                cabinetStatus === cabinetStatusMapping[VACANT]
                  ? currentVacancyQty + 1
                  : currentVacancyQty
            }
          },
          sizesSet: sizesSet.add(cabinetSize)
        };
      },
      {
        sizesMap: {} as CabinetSizesMap,
        sizesSet: new Set<string>()
      }
    );

  return Array.from(cabinetSizesSet).map(
    (size: string) => cabinetSizesMap[size]
  );
};

const RetrieveCabinetSizes = ({
  setCabinetSizes
}: {
  setCabinetSizes: SetState<CabinetSize[]>;
}) => {
  const { cabinets } = useGetCabinets();
  const { cabinetSizeMaster } = useMasterDataContext();

  useEffect(() => {
    const cabinetSizes = generateCabinetSizes({ cabinets, cabinetSizeMaster });
    setCabinetSizes(cabinetSizes);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinets]);

  return null;
};

export default RetrieveCabinetSizes;

// hooks
import { useLocaleContext, useRouter } from "@/hooks";
// components
import NextStepButton from "../NextStepButton";
// others
import styles from "./styles.module.less";

const BackHomeButton = () => {
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();

  return (
    <NextStepButton
      title={localeDataSource["Common.back.home.text"]}
      onClick={() => router.goHome()}
      customStyle={styles.backHomeButton}
    />
  );
};
export default BackHomeButton;

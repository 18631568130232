// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const ScanningGuide = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <p className={styles.scanningGuideWrapper}>
      {localeDataSource["BarcodeScanner.scanning.guide"]}
    </p>
  );
};
export default ScanningGuide;

// types
import { DoSignIn as IDoSignIn } from "@/types/screens/SignInOTP";
import { SetState } from "@/types/common";
// hooks
import { useLocaleContext, useRouter, useUpdateEffect } from "@/hooks";
// others
import { showToastMessage } from "@/utils";

const DoSignIn = ({
  triggerSignIn,
  verificationCode,
  doSignIn,
  setTriggerRedirect
}: {
  triggerSignIn: number;
  verificationCode: string;
  doSignIn: IDoSignIn;
  setTriggerRedirect: SetState<number>;
}) => {
  const router = useRouter();
  const { localeDataSource } = useLocaleContext();
  const { verificationId } = router.state || {};

  useUpdateEffect(() => {
    if (!triggerSignIn) return;

    (async () => {
      try {
        await doSignIn(verificationId, verificationCode);
        setTriggerRedirect(new Date().getTime());
      } catch (error) {
        showToastMessage(localeDataSource.SIGN_IN_OTP_MSG_E_001);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSignIn]);

  return null;
};

export default DoSignIn;

// types
import { SendingStep } from "@/types/screens/SendingProcess";

export const sendingSteps: SendingStep[] = [
  {
    label: "Common.step.label.1",
    content: "Common.step.content.1"
  },
  {
    label: "Common.step.label.2",
    content: "Common.step.content.2.deposit.pickup"
  },
  {
    label: "Common.step.label.3",
    content: "Common.step.content.3"
  }
];

// components
import CabinetSize from "./mains/CabinetSize";
// others
import styles from "./styles.module.less";

const RentalCabinetNo = ({
  cabinetNo,
  cabinetSize
}: {
  cabinetNo: string;
  cabinetSize: string;
}) => (
  <div className={styles.rentalCabinetNoWrapper}>
    <h2 className={styles.cabinetNo}>{cabinetNo}</h2>
    <CabinetSize {...{ cabinetSize }} />
  </div>
);

export default RentalCabinetNo;

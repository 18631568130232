// hooks
import { useRouter } from "@/hooks";
// components
import RentalCabinetNo from "@/components/RentalCabinetNo";
// other
import styles from "./styles.module.less";

const CabinetNo = () => {
  const router = useRouter();
  // TODO: add type for router state
  const { rentalInfo } = router.state || {};
  const { cabinetNo, cabinetSize } = rentalInfo || {};

  return (
    <div className={styles.cabinetNoWrapper}>
      <RentalCabinetNo {...{ cabinetNo, cabinetSize }} />
    </div>
  );
};

export default CabinetNo;

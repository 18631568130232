// hooks
import { useDelayRender, useGetLocker } from "@/hooks";
import useBarcodeScanner from "@/hooks/useBarcodeScanner";
// components
import ProcessIndicator from "@/components/ProcessIndicator";
import BarcodeScreenName from "./mains/BarcodeScreenName";
import CameraScanner from "./mains/CameraScanner";
import ScanningCancelButton from "./mains/ScanningCancelButton";
// ghosts
import RedirectOperation from "./ghosts/RedirectOperation";
// others
import styles from "./styles.module.less";

const BarcodeScanner = () => {
  const { setScanned, handleBarcodeScanned, scanned, barcodeInfo } =
    useBarcodeScanner();
  const { lockerId } = barcodeInfo || {};
  const { lockerInfo } = useGetLocker({ lockerId });
  const isDelayed = useDelayRender();

  return (
    <ProcessIndicator isLoading={isDelayed || !scanned} presentation="overlay">
      <div className={styles.barcodeScannerWrapper}>
        <div className={styles.barcodeScannerWrapperInner}>
          <BarcodeScreenName />
          <CameraScanner {...{ scanned, handleBarcodeScanned, setScanned }} />
          <ScanningCancelButton {...{ setScanned }} />
          <RedirectOperation {...{ lockerInfo }} />
        </div>
      </div>
    </ProcessIndicator>
  );
};

export default BarcodeScanner;

// libs
import { useMemo } from "react";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate
} from "react-router-dom";
// others
import CONSTANTS from "@/constants";

const { HOME } = CONSTANTS.ROUTERS;

const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return useMemo(
    () => ({
      location,
      navigate,
      goBack: () => navigate(-1),
      goHome: (options?: NavigateOptions) => navigate(HOME, options),
      state: location.state,
      replace: (to: To, options?: Omit<NavigateOptions, "replace">) =>
        navigate(to, { ...options, replace: true })
    }),
    [navigate, location]
  );
};

export default useRouter;

// [CLC] Code mapping: https://citynow-org.atlassian.net/l/c/wppTG2kp
import CABINET_STATUS from "./cabinetStatusMapping";
import RENTAL_OPERATION from "./rentalOperationMapping";
import DOOR_STATUS from "./doorStatusMapping";

export default {
  CABINET_STATUS,
  RENTAL_OPERATION,
  DOOR_STATUS
};

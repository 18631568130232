// hooks
import { useAuthContext } from "@/hooks";
// others
import { formatAvatarTitle } from "@/utils";
import styles from "./styles.module.less";

const TextAvatar = () => {
  const { user } = useAuthContext();
  const { displayName } = user || {};

  return (
    <div className={styles.textAvatarWrapper}>
      {formatAvatarTitle(displayName || "")}
    </div>
  );
};

export default TextAvatar;

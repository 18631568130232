const APP_SCREEN_NAMES = {
  SPLASH: "Splash",
  SIGN_IN: "SignIn",
  SIGN_IN_OTP: "SignInOTP",
  WELCOME_NEW_USER: "WelcomeNewUser",
  HOME: "Home"
};

const MAIN_SCREEN_NAMES = {
  BARCODE_SCANNER: "BarcodeScanner",
  CABINET_ASSIGN: "CabinetAssign",
  SENDING_PROCESS: "SendingProcess",
  SENDING_COMPLETE: "SendingComplete"
};

const CHILD_SCREEN_NAMES = {
  SIGN_OUT: "SignOut",
  SWITCH_LOCALE: "SwitchLocale",
  SWITCH_PREFIX: "SwitchPrefix"
};

export default {
  ...APP_SCREEN_NAMES,
  ...MAIN_SCREEN_NAMES,
  ...CHILD_SCREEN_NAMES
};

// types
import { SetState } from "@/types/common";
// components
import ScanningBlock from "../../components/ScanningBlock";
import CameraBlock from "../../components/CameraBlock";
// others
import styles from "./styles.module.less";

const CameraScanner = ({
  scanned,
  setScanned,
  handleBarcodeScanned
}: {
  scanned: boolean;
  setScanned: SetState<boolean>;
  handleBarcodeScanned: (data: string) => void;
}) => (
  <div className={styles.cameraScannerWrapper}>
    {scanned && <ScanningBlock />}
    <CameraBlock {...{ handleBarcodeScanned, setScanned }} />
  </div>
);

export default CameraScanner;

// libs
import { useState } from "react";
// types
import {
  DoSendParcel,
  DoCancelRentProcessing
} from "@/types/screens/SendingProcess";
// hooks
import { useLoading, useLocaleContext } from "@/hooks";
// components
import OperationProcess from "@/components/OperationProcess";
import ParcelNotFitButton from "@/components/ParcelNotFit/ParcelNotFitButton";
import ParcelNotFitModal from "@/components/ParcelNotFit/ParcelNotFitModal";
import ConfirmCancelModal from "@/components/ParcelNotFit/ConfirmCancelModal";
import ProcessIndicator from "@/components/ProcessIndicator";
// ghosts
import ReceiveDoorCloseSignal from "./ghosts/ReceiveDoorCloseSignal";
import SendParcel from "./ghosts/SendParcel";
import CancelRentProcessing from "./ghosts/CancelRentProcessing";
import PreventBrowserUnload from "./ghosts/PreventBrowserUnload";
// serverless
import { RentalOperation } from "@/serverless";
// dataSources
import { sendingSteps } from "@/dataSources/SendingProcess";
// others
import styles from "./styles.module.less";

const SendingProcess = () => {
  const [showParcelNotFitModal, setShowParcelNotFitModal] = useState(false);
  const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
  const [triggerSendParcel, setTriggerSendParcel] = useState(0);
  const [triggerCancelRental, setTriggerCancelRental] = useState(0);
  const { localeDataSource } = useLocaleContext();
  const [doSendParcel, sendingParcel] = useLoading<DoSendParcel>(
    RentalOperation.sendParcel
  );
  const [doCancelRentProcessing, cancelRentProcessing] =
    useLoading<DoCancelRentProcessing>(RentalOperation.cancelRentProcessing);

  return (
    <ProcessIndicator
      isLoading={sendingParcel || cancelRentProcessing}
      presentation="overlay"
    >
      <div className={styles.sendingProcessWrapper}>
        <OperationProcess
          headingTitle={
            localeDataSource["SendingProcess.sending.guide.heading"]
          }
          implementationProcess={sendingSteps}
          messageWarning={localeDataSource["Common.close.door.warning"]}
        />
        <ParcelNotFitButton {...{ setShowParcelNotFitModal }} />
        <ParcelNotFitModal
          {...{
            showParcelNotFitModal,
            setShowConfirmCancelModal,
            setShowParcelNotFitModal
          }}
        />
        <ConfirmCancelModal {...{ showConfirmCancelModal }} />
        <ReceiveDoorCloseSignal
          {...{
            setTriggerSendParcel,
            setTriggerCancelRental,
            setShowConfirmCancelModal,
            showConfirmCancelModal
          }}
        />
        <SendParcel {...{ triggerSendParcel, doSendParcel }} />
        <CancelRentProcessing
          {...{ triggerCancelRental, doCancelRentProcessing }}
        />
        <PreventBrowserUnload />
      </div>
    </ProcessIndicator>
  );
};

export default SendingProcess;

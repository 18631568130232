// libs
import { useEffect, useState } from "react";
// hooks
import useLocaleContext from "./useLocaleContext";
import useRouter from "./useRouter";
// others
import { CabinetOperation } from "@/serverless";
import { showToastMessage } from "@/utils";

const useOpenCabinet = ({
  cabinetNo,
  lockerId
}: {
  cabinetNo: string;
  lockerId: string;
}) => {
  const [triggerOpenCabinet, setTriggerOpenCabinet] = useState(0);
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();

  useEffect(() => {
    if (!cabinetNo || !lockerId || !triggerOpenCabinet) return;

    (async () => {
      try {
        await CabinetOperation.openCabinet({ lockerId, cabinetNo });
      } catch {
        showToastMessage(localeDataSource.COMMON_MSG_E_005);
        router.goHome();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerOpenCabinet]);

  return { setTriggerOpenCabinet };
};

export default useOpenCabinet;

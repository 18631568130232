// libs
import { Toast } from "antd-mobile";
// others
import CONSTANTS from "@/constants";

const { VI_VN } = CONSTANTS.LOCALE;

export const formatAvatarTitle = (displayName: string) => {
  if (!displayName) return "";

  const firstLetters = displayName.split(" ").map((token) => token[0]);
  const lastNameFirstLetter = firstLetters[firstLetters.length - 1];
  const middleNameFirstLetter = firstLetters[firstLetters.length - 2];

  return [middleNameFirstLetter, lastNameFirstLetter].join("").toUpperCase();
};

// TODO: support empty string, check where using it
export const formatSI = (
  stringDataSource: string,
  stringInterpolation: object
) => {
  if (stringDataSource === "") return "";

  if (!stringDataSource) {
    throw TypeError("stringDataSource should be a string");
  }
  if (!stringInterpolation) {
    throw TypeError("stringInterpolation should be an array or object");
  }
  let stringFormatted = stringDataSource;
  // stringInterpolation = ["A", 20]
  // Result: placeholderNames = [["0", "A"], ["1", 20]]
  // stringInterpolation = { name: "A", age: [20]}
  // Result: 8 = [["name", "A"], ["age", 20]]
  const placeholderNames = Object.entries({ ...stringInterpolation });
  placeholderNames.forEach(([placeholderName, placeholderValue]) => {
    stringFormatted = stringFormatted.replace(
      new RegExp(`\\[${placeholderName}\\]`, "g"),
      `${placeholderValue}`
    );
  });
  return stringFormatted;
};

export const formatLockerName = (lockerNo: string, lockerName: string) =>
  formatSI("[lockerName] ([lockerNo])", { lockerName, lockerNo });

export const isJSON = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const showToastMessage = (message: string) =>
  Toast.show({
    duration: 2000,
    content: message
  });

export const formatPhoneNo = (
  phoneNo: string,
  prefix: string = CONSTANTS.PHONE_NO_PREFIX[
    VI_VN as keyof typeof CONSTANTS.PHONE_NO_PREFIX
  ]
) =>
  formatSI("[prefix][phoneNo]", {
    prefix,
    phoneNo: phoneNo?.replace(/^0/, "") // remove leading zero
  });

export const isNullObject = (object: object) =>
  Object.keys(object).length === 0;

export const validateBarcode = (scannedData: string) => {
  if (!isJSON(scannedData)) return false;

  const parsedBarcode = JSON.parse(scannedData);
  const { lockerId } = parsedBarcode || {};
  if (!lockerId) return false;

  return parsedBarcode;
};

// libs
import { Button } from "antd-mobile";
import classNames from "classnames";
import { useFormikContext } from "formik";
// types
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
// components
import SizeSymbol from "../SizeSymbol";
import CabinetInfo from "../CabinetInfo";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { CABINET_SIZE } = CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;

const CabinetSizeItem = ({
  width,
  height,
  vacancyQty,
  cabinetSize
}: {
  width: string;
  height: string;
  vacancyQty: number;
  cabinetSize: string;
}) => {
  const { values, setFieldValue } = useFormikContext<CabinetAssignValues>();

  const isChosen = values[CABINET_SIZE] === cabinetSize;

  return (
    <div className={styles.cabinetSizeItemWrapper}>
      <Button
        className={classNames(styles.cabinetSizeItemWrapperInner, {
          [styles.active]: isChosen
        })}
        onClick={() => setFieldValue(CABINET_SIZE, cabinetSize, false)}
        disabled={vacancyQty === 0}
      >
        <SizeSymbol {...{ cabinetSize, isChosen }} />
        <CabinetInfo {...{ width, height, vacancyQty, isChosen }} />
      </Button>
    </div>
  );
};

export default CabinetSizeItem;

// libs
import { Card } from "antd-mobile";
// components
import AppLogo from "../../components/AppLogo";
import ServiceContent from "../../components/ServiceContent";
import GooglePlaySection from "../../components/GooglePlaySection";
import AppStoreSection from "../../components/AppStoreSection";

const ServiceSection = () => (
  <Card>
    <AppLogo />
    <ServiceContent />
    <GooglePlaySection />
    <AppStoreSection />
  </Card>
);

export default ServiceSection;

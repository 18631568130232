// components
import BannerWithHeading from "../BannerWithHeading";
import ImageWithSkeleton from "../ImageWithSkeleton";

const StaticBanner = ({
  bannerSource,
  headingTitle,
  subheadingTitle
}: {
  bannerSource: string;
  headingTitle: string;
  subheadingTitle?: string;
}) => (
  <BannerWithHeading {...{ headingTitle, subheadingTitle }}>
    <ImageWithSkeleton
      src={bannerSource}
      alt="graphic-banner"
      height="200px"
      width="100%"
    />
  </BannerWithHeading>
);

export default StaticBanner;

// types
import { SetState } from "@/types/common";
// hooks
import { useRouter, useCustomConfirmModal } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { PHONE_NO_PREFIX } = CONSTANTS.SCREEN_FIELD_NAMES.SIGN_IN;

const CustomConfirmButton = ({
  selectedPrefix
}: {
  selectedPrefix: string;
  setSelectedPrefix: SetState<string>;
}) => {
  const router = useRouter();
  const { background } = router.state || {};

  useCustomConfirmModal(() => {
    // TODO: block back
    router.navigate(background, {
      state: { ...background.state, [PHONE_NO_PREFIX]: selectedPrefix }
    });
  }, [selectedPrefix]);

  return null;
};

export default CustomConfirmButton;

// libs
import { useEffect } from "react";
// types
import { SetState } from "@/types/common";
// hooks
import { useRouter, useWaitDoorClose } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { LOCKER_ID, CABINET_NO } = CONSTANTS.COLLECTION_FIELD_NAMES.RENTALS;

const ReceiveDoorCloseSignal = ({
  setTriggerSendParcel,
  setTriggerCancelRental,
  setShowConfirmCancelModal,
  showConfirmCancelModal
}: {
  setTriggerSendParcel: SetState<number>;
  setTriggerCancelRental: SetState<number>;
  setShowConfirmCancelModal: SetState<boolean>;
  showConfirmCancelModal: boolean;
}) => {
  const router = useRouter();
  const { rentalInfo } = router.state || {};
  const { isClosed } = useWaitDoorClose({
    [LOCKER_ID]: rentalInfo[LOCKER_ID],
    [CABINET_NO]: rentalInfo[CABINET_NO]
  });

  useEffect(() => {
    if (!isClosed) return;

    if (!showConfirmCancelModal) {
      setTriggerSendParcel(new Date().getTime());
    } else {
      setShowConfirmCancelModal(false);
      setTriggerCancelRental(new Date().getTime());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosed]);

  return null;
};

export default ReceiveDoorCloseSignal;

// others
import welcomeNewUserFormData from "../data/WelcomeNewUser";
import WELCOME_NEW_USER_SCHEMA from "../validations/WelcomeNewUser";

const welcomeNewUserFormikProps = ({
  localeDataSource
}: {
  localeDataSource: Record<string, string>;
}) => ({
  ...welcomeNewUserFormData,
  enableReinitialize: true,
  validationSchema: WELCOME_NEW_USER_SCHEMA({ localeDataSource })
});

export default welcomeNewUserFormikProps;

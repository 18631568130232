// libs
import { useState } from "react";
// hooks
import { useLocaleContext } from "@/hooks";
// components
import LocaleCheckboxes from "./mains/LocaleCheckboxes";
// ghosts
import CustomConfirmButton from "./ghosts/CustomConfirmButton";
// others
import styles from "./styles.module.less";

const SwitchLocale = () => {
  const { locale } = useLocaleContext();
  const [selectedLocale, setSelectedLocale] = useState<string>(locale);

  return (
    <div className={styles.switchLocaleContentWrapper}>
      <LocaleCheckboxes {...{ selectedLocale, setSelectedLocale }} />
      <CustomConfirmButton {...{ selectedLocale }} />
    </div>
  );
};

export default SwitchLocale;

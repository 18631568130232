export default {
  "Common.confirm.text": "OK",
  "Common.cancel.text": "Cancel",
  "Common.enter.full.name.placeholder": "ENTER FULL NAME",
  "Common.back.home.text": "Back home",
  "Common.complete.subhead":
    "Thank you for using CloudLocker services.\nWe hope you have a great experience!",
  "Common.close.door.warning":
    "Please close the door after\ncompleting the operation",
  "Common.step.label.1": "Step 1:",
  "Common.step.label.2": "Step 2:",
  "Common.step.label.3": "Step 3:",
  "Common.step.content.1": "Observe the opened cabinet",
  "Common.step.content.2.deposit.pickup": "Deposit or pick up your parcel",
  "Common.step.content.3": "Close the door",
  "Common.confirm.modal.ok": "OK",
  "Common.confirm.modal.cancel": "Cancel",
  "Common.parcel.not.fit": "Parcel not fit?",
  "Common.parcel.not.fit.confirm.message":
    "Are you sure you want to change the cabinet size?\nPress “OK” to cancel this process.\nAfter that, scan QR code again to start a new rental.",
  "Common.parcel.not.fit.warning":
    "Please close the door to\ncomplete the operation",
  "Common.next.step.text": "Next",
  "Common.process.indicator": "Loading..."
};

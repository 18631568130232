// libs
import { useCallback } from "react";
import { useBeforeUnload } from "react-router-dom";

const PreventBrowserUnload = () => {
  // Some method won't work to prompt user when unload due to browser compatibility.
  // For further information please read the references below
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#compatibility_notes
  const preventUnloadCallback = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = "";
    return "";
  }, []);

  useBeforeUnload(preventUnloadCallback);

  return null;
};

export default PreventBrowserUnload;

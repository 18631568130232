// hooks
import { useLocaleContext, useModalContext } from "@/hooks";
// components
import NextStepButton from "@/components/NextStepButton";
// others
import styles from "./styles.module.less";

const ConfirmButton = () => {
  const { localeDataSource } = useLocaleContext();
  const { onOk } = useModalContext();

  return (
    <NextStepButton
      title={localeDataSource["Common.confirm.text"]}
      customStyle={styles.confirmButton}
      onClick={onOk}
    />
  );
};

export default ConfirmButton;

// hooks
import { useLocaleContext } from "@/hooks";
// components
import NextStepButton from "@/components/NextStepButton";
import useSubmit from "@/hooks/useSubmit";

const SignInButton = ({
  gettingVerificationId
}: {
  gettingVerificationId: boolean;
}) => {
  const { localeDataSource } = useLocaleContext();
  const { doSubmit } = useSubmit();

  return (
    <NextStepButton
      title={localeDataSource["SignIn.sign.in.button"]}
      onClick={doSubmit}
      loading={gettingVerificationId}
    />
  );
};

export default SignInButton;

// components
import ImageWithSkeleton from "@/components/ImageWithSkeleton";

const AppLogoBranding = () => (
  <a href="/">
    <ImageWithSkeleton
      src="/assets/images/Home/home_logo_has_branding.png"
      alt="app-logo-branding"
      height={32}
      width={180}
    />
  </a>
);

export default AppLogoBranding;

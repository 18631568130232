// types
import {
  CancelRentProcessing,
  SendParcel
} from "@/types/serverless/mixins/rentals";
// others
import CONSTANTS from "@/constants";
import { FirestoreOperation, FunctionsOperation } from "../sdk";

const { RENTALS } = CONSTANTS.COLLECTIONS;
const {
  RENTALS: { RENTAL_ID, LOCKER_ID, CABINET_NO }
} = CONSTANTS.COLLECTION_FIELD_NAMES;
const { SEND_PARCEL, CANCEL_RENT_PROCESSING } = CONSTANTS.FUNCTION_NAMES;

class RentalMixin {
  firestoreOpt: FirestoreOperation;

  static collectionId: string = RENTALS;

  static docId: string = RENTAL_ID;

  /**
   * Compose FirestoreOperation
   */
  constructor({ collectionPath }: Record<string, string>) {
    this.firestoreOpt = new FirestoreOperation({
      docId: RentalMixin.docId,
      collectionPath
    });
  }

  static async sendParcel(rentalInfo: SendParcel.Params) {
    return FunctionsOperation.callable<SendParcel.Params, null>(SEND_PARCEL)(
      rentalInfo
    );
  }

  static cancelRentProcessing({
    lockerId,
    cabinetNo
  }: CancelRentProcessing.Params) {
    return FunctionsOperation.callable<CancelRentProcessing.Params, null>(
      CANCEL_RENT_PROCESSING
    )({ [LOCKER_ID]: lockerId, [CABINET_NO]: cabinetNo });
  }
}

export default RentalMixin;

// components
import CustomModal from "@/components/CustomModal";
import ConfirmCancelIcon from "./mains/ConfirmCancelIcon";
import ConfirmCancelMessage from "./mains/ConfirmCancelMessage";

const ConfirmCancelModal = ({
  showConfirmCancelModal
}: {
  showConfirmCancelModal: boolean;
}) => (
  <CustomModal
    isVisible={showConfirmCancelModal}
    showFooter={false}
    title={<ConfirmCancelIcon />}
  >
    <ConfirmCancelMessage />
  </CustomModal>
);

export default ConfirmCancelModal;

// libs
import { Checkbox } from "antd-mobile";
// types
import { SetState } from "@/types/common";
// components
import MatIcon from "@/components/MatIcon";
// dataSources
import { switchLocaleList } from "@/dataSources/SwitchLocale";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { AZURE_RADIANCE } = CONSTANTS.COLORS;

const SwitchLocale = ({
  selectedLocale,
  setSelectedLocale
}: {
  selectedLocale: string;
  setSelectedLocale: SetState<string>;
}) => (
  <>
    {switchLocaleList.map(({ localeTitle, localeKey }) => (
      <Checkbox
        key={localeKey}
        className={styles.localeCheckBoxWrapper}
        checked={selectedLocale === localeKey}
        onChange={() => setSelectedLocale(localeKey)}
        icon={(checked: boolean) => (
          <MatIcon
            name={checked ? "mdi-radiobox-marked" : "mdi-radiobox-blank"}
            size="20"
            color={AZURE_RADIANCE}
          />
        )}
      >
        {localeTitle}
      </Checkbox>
    ))}
  </>
);

export default SwitchLocale;

// types
import { SetState } from "@/types/common";
// components
import CustomModal from "@/components/CustomModal";
import ParcelNotFitIcon from "./mains/ParcelNotFitIcon";
import ParcelNotFitConfirm from "./mains/ParcelNotFitConfirm";
// ghosts
import CustomConfirmButton from "./ghost/CustomConfirmButton";
import CustomCancelButton from "./ghost/CustomCancelButton";

const ParcelNotFitModal = ({
  showParcelNotFitModal,
  setShowParcelNotFitModal,
  setShowConfirmCancelModal
}: {
  showParcelNotFitModal: boolean;
  setShowParcelNotFitModal: SetState<boolean>;
  setShowConfirmCancelModal: SetState<boolean>;
}) => (
  <CustomModal isVisible={showParcelNotFitModal} title={<ParcelNotFitIcon />}>
    <ParcelNotFitConfirm />
    <CustomConfirmButton
      {...{ setShowParcelNotFitModal, setShowConfirmCancelModal }}
    />
    <CustomCancelButton {...{ setShowParcelNotFitModal }} />
  </CustomModal>
);

export default ParcelNotFitModal;

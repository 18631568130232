// [CLC] Code mapping, No.1: https://citynow-org.atlassian.net/l/cp/ANh5EX9B

const CABINET_STATUS = {
  VACANT: "vacant",
  RENT_PROCESSING: "rentProcessing",
  RENTING: "renting",
  STOP_SELLING: "stopSelling"
};

const cabinetStatusMapping = {
  [CABINET_STATUS.VACANT]: "0",
  [CABINET_STATUS.RENT_PROCESSING]: "1",
  [CABINET_STATUS.RENTING]: "2",
  [CABINET_STATUS.STOP_SELLING]: "3",
  0: CABINET_STATUS.VACANT,
  1: CABINET_STATUS.RENT_PROCESSING,
  2: CABINET_STATUS.RENTING,
  3: CABINET_STATUS.STOP_SELLING
};

export default { CABINET_STATUS, cabinetStatusMapping };

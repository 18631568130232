// others
import signInFormData from "../data/SignIn";
import SIGN_IN_SCHEMA from "../validations/SignIn";

const signInFormikProps = ({
  localeDataSource
}: {
  localeDataSource: Record<string, string>;
}) => ({
  ...signInFormData,
  enableReinitialize: true,
  validationSchema: SIGN_IN_SCHEMA({ localeDataSource })
});

export default signInFormikProps;

// libs
import { useMemo, useState } from "react";
import { Formik } from "formik";
// types
import { CabinetSize } from "@/types/screens/CabinetAssign";
// hooks
import {
  useAuthContext,
  useLocaleContext,
  useOpenCabinet,
  useRouter
} from "@/hooks";
// components
import LockerHeaderTitle from "@/components/LockerHeaderTitle";
import ReceiverSection from "./mains/ReceiverSection";
import NoteSection from "./mains/NoteSection";
import CabinetSizeSection from "./mains/CabinetSizeSection";
import SendingButton from "./mains/SendingButton";
import ProcessIndicator from "@/components/ProcessIndicator";
import StockoutAlert from "./mains/StockoutAlert";
// ghosts
import RetrieveCabinetSizes from "./ghosts/RetrieveCabinetSizes";
import CheckStockout from "./ghosts/CheckStockout";
import AssignCabinet from "./ghosts/AssignCabinet";
import ReceiveDoorOpenSignal from "./ghosts/ReceiveDoorOpenSignal";
import RedirectSendingProcess from "./ghosts/RedirectSendingProcess";
// others
import cabinetAssignFormikProps from "@/formiks/props/CabinetAssign";
import styles from "./styles.module.less";

const CabinetAssign = () => {
  const [cabinetSizes, setCabinetSizes] = useState<CabinetSize[]>([]);
  const [isStockout, setIsStockout] = useState(false);
  const [triggerAssignCabinet, setTriggerAssignCabinet] = useState(0);
  const [assignedCabinetNo, setAssignedCabinetNo] = useState("");
  const [showOverlay, setShowOverlay] = useState(false);
  const [triggerRedirectProcess, setTriggerRedirectProcess] = useState(0);
  const { user } = useAuthContext();
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();
  const { lockerInfo } = router.state || {};
  const { lockerId } = lockerInfo || {};
  const { setTriggerOpenCabinet } = useOpenCabinet({
    cabinetNo: assignedCabinetNo,
    lockerId
  });
  const { phoneNumber } = user || {};

  const initFormikProps = useMemo(
    () => cabinetAssignFormikProps({ localeDataSource, phoneNumber }),
    [localeDataSource, phoneNumber]
  );

  const onSubmit = () => setTriggerAssignCabinet(new Date().getTime());

  return (
    <Formik {...initFormikProps} onSubmit={onSubmit}>
      <div className={styles.cabinetAssignWrapper}>
        <LockerHeaderTitle />
        <ProcessIndicator
          isLoading={!cabinetSizes.length || showOverlay}
          presentation={showOverlay ? "overlay" : "card"}
        >
          <div className={styles.cabinetAssignWrapperInner}>
            <ReceiverSection />
            <NoteSection />
            <CabinetSizeSection {...{ cabinetSizes }} />
            <StockoutAlert {...{ isStockout, setIsStockout }} />
            <SendingButton />
          </div>
        </ProcessIndicator>
        <CheckStockout {...{ cabinetSizes, setIsStockout }} />
        <RetrieveCabinetSizes {...{ setCabinetSizes }} />
        <AssignCabinet
          {...{
            triggerAssignCabinet,
            setAssignedCabinetNo,
            setShowOverlay,
            setTriggerOpenCabinet
          }}
        />
        <ReceiveDoorOpenSignal
          {...{ assignedCabinetNo, setShowOverlay, setTriggerRedirectProcess }}
        />
        <RedirectSendingProcess
          {...{ assignedCabinetNo, triggerRedirectProcess }}
        />
      </div>
    </Formik>
  );
};
export default CabinetAssign;

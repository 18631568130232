const error = {
  CABINET_ASSIGN_MSG_E_001: "Vui lòng chọn kích thước ô tủ.",
  CABINET_ASSIGN_MSG_E_002:
    "Số điện thoại này không phải là của khách hàng CloudLocker. Hãy nhập số điện thoại khác.",
  CABINET_ASSIGN_MSG_E_003:
    "Bạn không thể gửi hàng cho chính mình. Hãy nhập số điện thoại khác."
};

const info = {
  CABINET_ASSIGN_MSG_I_001: "Bạn có chắc chắn muốn\ngửi hàng?"
};

export default {
  ...error,
  ...info
};

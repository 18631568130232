// others
import cabinetAssignFormData from "../data/CabinetAssign";
import CABINET_ASSIGN_SCHEMA from "../validations/CabinetAssign";

const cabinetAssignFormikProps = ({
  localeDataSource,
  phoneNumber
}: {
  localeDataSource: Record<string, string>;
  phoneNumber: string | null;
}) => ({
  ...cabinetAssignFormData(),
  enableReinitialize: true,
  validationSchema: CABINET_ASSIGN_SCHEMA({ localeDataSource, phoneNumber }),
  validateOnMount: true
});

export default cabinetAssignFormikProps;

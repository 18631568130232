// libs
import { Input, SpinLoading } from "antd-mobile";
import { useFormikContext } from "formik";
// types
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
import { SetState } from "@/types/common";
// components
import MatIcon from "@/components/MatIcon";
// hooks
import { useLocaleContext } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { RECEIVER_PHONE_NO } = CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;
const { PHONE_NO_MIN_LENGTH } = CONSTANTS.CODE_LENGTH;

const ReceiverPhoneNo = ({
  gettingReceiverName,
  setTriggerGetReceiverName
}: {
  gettingReceiverName: boolean;
  setTriggerGetReceiverName: SetState<number>;
}) => {
  const { localeDataSource } = useLocaleContext();
  const { values, handleChange } = useFormikContext<CabinetAssignValues>();

  return (
    <div className={styles.receiverPhoneNoWrapper}>
      <MatIcon name="mdi-phone-outline" size="20px" />
      <Input
        placeholder={localeDataSource["CabinetAssign.receiver.phone.no"]}
        type="tel"
        maxLength={PHONE_NO_MIN_LENGTH}
        className={styles.inputPhoneNo}
        value={values[RECEIVER_PHONE_NO]}
        onChange={handleChange(RECEIVER_PHONE_NO)}
        onBlur={() => setTriggerGetReceiverName(new Date().getTime())}
      />
      {gettingReceiverName && <SpinLoading color="primary" />}
    </div>
  );
};
export default ReceiverPhoneNo;

const APP_ROUTES = {
  SPLASH: "/splash",
  SIGN_IN: "/sign-in",
  SIGN_IN_OTP: "/sign-in-otp",
  WELCOME_NEW_USER: "/welcome-new-user",
  HOME: "/"
};

const MAIN_ROUTES = {
  BARCODE_SCANNER: "/barcode-scanner",
  CABINET_ASSIGN: "/cabinet-assign",
  SENDING_PROCESS: "/sending-process",
  SENDING_COMPLETE: "/sending-complete"
};

const CHILD_ROUTES = {
  SIGN_OUT: "/sign-out",
  SWITCH_LOCALE: "/switch-locale",
  SWITCH_PREFIX: "/switch-prefix"
};

export default {
  ...APP_ROUTES,
  ...MAIN_ROUTES,
  ...CHILD_ROUTES
};

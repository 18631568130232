// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const ParcelNotFitConfirm = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.parcelNotFitConfirmWrapper}>
      {localeDataSource["Common.parcel.not.fit.confirm.message"]}
    </div>
  );
};

export default ParcelNotFitConfirm;

// libs
import { useEffect } from "react";
// types
import { CabinetSize } from "@/types/screens/CabinetAssign";
import { SetState } from "@/types/common";

const CheckStockout = ({
  cabinetSizes,
  setIsStockout
}: {
  cabinetSizes: CabinetSize[];
  setIsStockout: SetState<boolean>;
}) => {
  useEffect(() => {
    if (!cabinetSizes.length) return;

    const allSizesVacancyQty = cabinetSizes.reduce<number>(
      (sumVacancyQty, { vacancyQty }) => sumVacancyQty + vacancyQty,
      0
    );
    setIsStockout(allSizesVacancyQty === 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cabinetSizes]);

  return null;
};

export default CheckStockout;

// libs
import classNames from "classnames";
// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const CabinetInfoItem = ({
  label,
  value,
  isChosen
}: {
  label: string;
  value: number | string;
  isChosen: boolean;
}) => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div
      className={classNames(styles.cabinetInfoItemWrapper, {
        [styles.active]: isChosen
      })}
    >
      <div className={styles.contentStyles}>{localeDataSource[label]}</div>
      <div className={styles.contentStyles}>{value}</div>
    </div>
  );
};
export default CabinetInfoItem;

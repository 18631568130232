/* eslint-disable max-classes-per-file */
// libs
import { httpsCallable } from "firebase/functions";
import {
  doc,
  getDoc,
  getDocs,
  query,
  collection,
  QueryConstraint
} from "firebase/firestore";
import { onValue, ref } from "firebase/database";
// others
import {
  FIREBASE_FUNCTIONS,
  FIREBASE_FIRESTORE,
  FIREBASE_DATABASE
} from "@/firebaseConfig";

export class FirestoreOperation {
  docId: string;
  collectionPath: string;
  docPath: string;

  /**
   * Init from Mixin through super()
   */
  constructor({
    docId,
    collectionPath,
    docPath
  }: {
    docId?: string;
    collectionPath?: string;
    docPath?: string;
  }) {
    // Type 'string | undefined' is not assignable to type 'string'
    // https://stackoverflow.com/a/60353941/13200598
    this.docId = docId ?? "";
    this.collectionPath = collectionPath ?? "";
    this.docPath = docPath ?? "";
  }

  provideQuery() {
    return {
      getDocument: async <T>() => {
        const docRef = doc(FIREBASE_FIRESTORE, this.docPath);
        const docSnap = await getDoc(docRef);

        return {
          data: { ...docSnap.data(), [this.docId]: docSnap.id } as T,
          exists: docSnap.exists()
        };
      },

      getDocumentsByCollection: async <T>(constraints: QueryConstraint[]) => {
        const collectionRef = collection(
          FIREBASE_FIRESTORE,
          this.collectionPath
        );
        const docQuery = query(collectionRef, ...constraints);
        const docSnap = await getDocs(docQuery);

        const docs = docSnap.docs.map((docItem) => ({
          ...docItem.data(),
          [this.docId]: docItem.id
        }));

        return docs as T[];
      }
    };
  }
}

export class DatabaseOperation {
  docPath: string;
  /**
   * Init from Mixin through super()
   */
  constructor({ docPath }: { docPath: string }) {
    this.docPath = docPath ?? "";
  }

  provideQuery() {
    return {
      listenDataChanges: <T>(callback: (value: T) => void) => {
        const docRef = ref(FIREBASE_DATABASE, this.docPath);

        return onValue(docRef, (snapShot) => callback(snapShot.val()));
      }
    };
  }
}

export class FunctionsOperation {
  static callable<RequestData, ResponseData>(functionName: string) {
    return httpsCallable<RequestData, ResponseData>(
      FIREBASE_FUNCTIONS,
      functionName
    );
  }
}

// libs
import { useState, useEffect } from "react";
// types
import { SetState } from "@/types/common";
// hooks
import useLocaleContext from "./useLocaleContext";
import useRouter from "./useRouter";
// others
import { CabinetOperation } from "@/serverless";
import { showToastMessage } from "@/utils";

const useWaitDoorOpen = ({
  cabinetNo,
  lockerId,
  setShowOverlay
}: {
  cabinetNo: string;
  lockerId: string;
  setShowOverlay: SetState<boolean>;
}) => {
  const [isOpened, setIsOpened] = useState(false);
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();

  useEffect(() => {
    if (!lockerId || !cabinetNo) return;
    (async () => {
      try {
        const cabinetOpt = new CabinetOperation({ lockerId, cabinetNo });
        await cabinetOpt.waitDoorOpen();

        setIsOpened(true);
      } catch {
        setShowOverlay(false);
        showToastMessage(localeDataSource.COMMON_MSG_E_005);
        router.goHome();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockerId, cabinetNo]);

  return { isOpened };
};

export default useWaitDoorOpen;

// types
import { SetState } from "@/types/common";
// components
import ResendMessage from "../../components/ResendMessage";
import ResendButton from "../../components/ResendButton";
import RetryMessage from "../../components/RetryMessage";
// ghosts
import CountdownRetry from "../../ghosts/CountdownRetry";
// others
import styles from "./styles.module.less";

const ResendSection = ({
  retryInterval,
  setRetryInterval,
  setTriggerGetVerificationId
}: {
  retryInterval: number;
  setRetryInterval: SetState<number>;
  setTriggerGetVerificationId: SetState<number>;
}) => (
  <div className={styles.resendSectionWrapper}>
    <ResendMessage />
    {retryInterval === 0 ? (
      <ResendButton {...{ setTriggerGetVerificationId }} />
    ) : (
      <RetryMessage {...{ retryInterval }} />
    )}
    <CountdownRetry {...{ retryInterval, setRetryInterval }} />
  </div>
);

export default ResendSection;

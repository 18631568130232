// components
import HomeHeader from "./mains/HomeHeader";
import ServiceSection from "@/components/Home/mains/ServiceSection";
import DeliverySection from "@/components/Home/mains/DeliverySection";
import CopyRightSection from "@/components/Home/mains/CopyRightSection";
// others
import styles from "./styles.module.less";

const UnauthHome = () => (
  <div className={styles.unauthHomeWrapper}>
    <div className={styles.unauthHomeWrapperInner}>
      <HomeHeader />
      <ServiceSection />
      <DeliverySection />
    </div>
    <CopyRightSection />
  </div>
);

export default UnauthHome;

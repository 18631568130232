// libs
import { useMemo } from "react";
// hooks
import useAuthContext from "./useAuthContext";
// others
import { isNullObject } from "@/utils";

const useAuthorized = () => {
  const { user } = useAuthContext();
  const tempUser = user || {};
  const { displayName } = tempUser;

  const notAuthenticatedUser = isNullObject(tempUser);
  const authenticatedNewUser = !notAuthenticatedUser && !displayName;
  const authenticatedReturningUser = !notAuthenticatedUser && !!displayName;

  return useMemo(
    () => ({
      notAuthenticatedUser,
      authenticatedNewUser,
      authenticatedReturningUser
    }),
    [notAuthenticatedUser, authenticatedNewUser, authenticatedReturningUser]
  );
};

export default useAuthorized;

// libs
import { useState, useEffect } from "react";
// types
import { Cabinet } from "@/types/firestore/cabinets";
// hooks
import useRouter from "./useRouter";
// others
import { CabinetOperation } from "@/serverless";

const useGetCabinets = () => {
  const [cabinets, setCabinets] = useState<Cabinet[]>([]);
  const router = useRouter();
  const { lockerInfo } = router.state || {};
  const { lockerId } = lockerInfo || {};

  useEffect(() => {
    (async () => {
      const cabinetOpt = new CabinetOperation({ lockerId });
      const cabinetList = await cabinetOpt.getCabinets();

      setCabinets(cabinetList);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { cabinets };
};

export default useGetCabinets;

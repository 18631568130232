// components
import { useEffect, useId } from "react";
import lottie from "lottie-web";
// components
import BannerWithHeading from "../BannerWithHeading";

const AnimatedBanner = ({
  bannerSource,
  headingTitle,
  subheadingTitle
}: {
  bannerSource: string;
  headingTitle: string;
  subheadingTitle?: string;
}) => {
  const id = useId();

  useEffect(() => {
    const container = document.getElementById(id);
    if (container) {
      lottie
        .loadAnimation({
          container,
          renderer: "svg",
          loop: true,
          autoplay: true,
          path: bannerSource
        })
        .setSpeed(1.5);
    }

    return () => {
      lottie.destroy();
    };
  }, [bannerSource, id]);

  return (
    <BannerWithHeading {...{ headingTitle, subheadingTitle }}>
      <div id={id} style={{ height: 200 }} />
    </BannerWithHeading>
  );
};

export default AnimatedBanner;

// libs
import { useFormikContext } from "formik";
// hooks
import useShowConfirmModal from "./useShowConfirmModal";
// others
import { showToastMessage } from "@/utils";

const useSubmit = () => {
  const { validateForm, handleSubmit } = useFormikContext();
  const { showConfirmModal } = useShowConfirmModal();

  const checkHasError = () =>
    validateForm().then((errorObj) => {
      const errors = Object.values<string>(errorObj);
      const hasError = errors.length > 0;

      if (hasError) {
        showToastMessage(errors[0]);
        return true;
      }

      return false;
    });

  const doSubmit = async () => {
    const hasError = await checkHasError();
    if (!hasError) handleSubmit();
  };

  const doConfirmSubmit = async (message: string) => {
    const hasError = await checkHasError();
    if (!hasError)
      showConfirmModal({
        title: message,
        onConfirm: handleSubmit
      });
  };

  return { doSubmit, doConfirmSubmit };
};

export default useSubmit;

// components
import { Card } from "antd-mobile";
import CardHeading from "./mains/CardHeading";
// others
import styles from "./styles.module.less";

const HeadingBlankCard = ({
  iconName,
  headingTitle,
  children
}: {
  iconName: string;
  headingTitle: string;
  children?: React.ReactNode;
}) => (
  <Card className={styles.headingBlankCardWrapper}>
    <CardHeading {...{ iconName, headingTitle }} />
    {children}
  </Card>
);

export default HeadingBlankCard;

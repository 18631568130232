// libs
import { Button } from "antd-mobile";
// types
import { SetState } from "@/types/common";
// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const ParcelNotFitButton = ({
  setShowParcelNotFitModal
}: {
  setShowParcelNotFitModal: SetState<boolean>;
}) => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.parcelNotFitButtonWrapper}>
      <Button
        className={styles.parcelNotFitButtonWrapperInner}
        onClick={() => setShowParcelNotFitModal(true)}
      >
        <p>{localeDataSource["Common.parcel.not.fit"]}</p>
      </Button>
    </div>
  );
};

export default ParcelNotFitButton;

// libs
import { Input } from "antd-mobile";
import { useFormikContext } from "formik";
// types
import { WelcomeNewUserValues } from "@/types/screens/WelcomeNewUser";
// hooks
import { useLocaleContext, useSubmit } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { DISPLAY_NAME } = CONSTANTS.SCREEN_FIELD_NAMES.WELCOME_NEW_USER;

const DisplayNameInput = () => {
  const { localeDataSource } = useLocaleContext();
  const { values, handleChange } = useFormikContext<WelcomeNewUserValues>();
  const { doSubmit } = useSubmit();

  return (
    <Input
      placeholder={localeDataSource["Common.enter.full.name.placeholder"]}
      className={styles.displayNameInputWrapper}
      name={DISPLAY_NAME}
      value={values[DISPLAY_NAME]}
      onChange={handleChange(DISPLAY_NAME)}
      onEnterPress={doSubmit}
    />
  );
};

export default DisplayNameInput;

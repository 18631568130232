// libs
import { Input } from "antd-mobile";
import { useFormikContext } from "formik";
// types
import { SignInValues } from "@/types/screens/SignIn";
// hooks
import { useLocaleContext, useSubmit } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { PHONE_NO } = CONSTANTS.SCREEN_FIELD_NAMES.SIGN_IN;
const { PHONE_NO_MIN_LENGTH } = CONSTANTS.CODE_LENGTH;

const PhoneNoInput = () => {
  const { localeDataSource } = useLocaleContext();
  const { values, handleChange } = useFormikContext<SignInValues>();
  const { doSubmit } = useSubmit();

  return (
    <div className={styles.phoneNoInputWrapper}>
      <Input
        maxLength={PHONE_NO_MIN_LENGTH}
        name={PHONE_NO}
        placeholder={localeDataSource["SignIn.phone.no.placeholder"]}
        type="tel"
        onChange={handleChange(PHONE_NO)}
        value={values[PHONE_NO]}
        onEnterPress={doSubmit}
      />
    </div>
  );
};

export default PhoneNoInput;

// libs
import { useFormikContext } from "formik";
// types
import {
  WelcomeNewUserValues,
  DoUpdateProfile,
  DoUpdateFirebaseProfile
} from "@/types/screens/WelcomeNewUser";
import { SetState } from "@/types/common";
// hooks
import { useAuthContext, useUpdateEffect } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { DISPLAY_NAME } = CONSTANTS.SCREEN_FIELD_NAMES.WELCOME_NEW_USER;

const MakeUserExist = ({
  triggerMakeUserExist,
  doUpdateFirebaseProfile,
  doUpdateProfile,
  setTriggerRedirect
}: {
  triggerMakeUserExist: number;
  doUpdateFirebaseProfile: DoUpdateFirebaseProfile;
  doUpdateProfile: DoUpdateProfile;
  setTriggerRedirect: SetState<number>;
}) => {
  const { values } = useFormikContext<WelcomeNewUserValues>();
  const { user } = useAuthContext();

  useUpdateEffect(() => {
    if (!triggerMakeUserExist) return;

    (async () => {
      const sanitizedDisplayName = values[DISPLAY_NAME].trim();

      await Promise.all([
        doUpdateFirebaseProfile(user, {
          [DISPLAY_NAME]: sanitizedDisplayName
        }),
        doUpdateProfile({ [DISPLAY_NAME]: sanitizedDisplayName })
      ]);

      setTriggerRedirect(new Date().getTime());
    })();
  }, [triggerMakeUserExist]);

  return null;
};

export default MakeUserExist;

// libs
import { useState, useEffect } from "react";

const useDelayRender = (delay = 300) => {
  const [isDelayed, setDelayed] = useState(true);

  useEffect(() => {
    const delayedTimer = setTimeout(() => {
      setDelayed(false);
    }, delay);

    return () => clearTimeout(delayedTimer);
  }, [delay]);

  return isDelayed;
};

export default useDelayRender;

// libs
import { useCallback, useEffect, useState } from "react";
// types
import { SetState } from "@/types/common";

const useLocalStorage = <T>(key: string, initialValue: T): [T, SetState<T>] => {
  const getStoredItem = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  }, [key, initialValue]);

  const [storedValue, setStoredValue] = useState<T>(getStoredItem());

  useEffect(() => {
    setStoredValue(getStoredItem());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValue: SetState<T> = (value) => {
    // Prevent build error "window is undefined" but keeps working
    try {
      // Allow value to be a function so we have the same API as useState
      const newValue =
        value instanceof Function ? value(storedValue as T) : value;
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(newValue));
      // Save state
      setStoredValue(newValue);
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  return [storedValue, setValue];
};

export default useLocalStorage;

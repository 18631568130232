// hooks
import { useLocaleContext } from "@/hooks";
// others
import { formatSI } from "@/utils";
import styles from "./styles.module.less";

const RetryMessage = ({ retryInterval }: { retryInterval: number }) => {
  const { localeDataSource } = useLocaleContext();

  return (
    <p className={styles.retryMessageWrapper}>
      {formatSI(localeDataSource["SignInOTP.retry.after.seconds"], [
        retryInterval
      ])}
    </p>
  );
};

export default RetryMessage;

// hooks
import { useLocaleContext } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { BARCODE_SCANNER } = CONSTANTS.SCREEN_NAMES;

const BarcodeScreenName = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <h2 className={styles.barcodeScreenNameWrapper}>
      {localeDataSource[BARCODE_SCANNER]}
    </h2>
  );
};

export default BarcodeScreenName;

// libs
import { useState } from "react";
// hooks
import { useRouter } from "@/hooks";
// components
import PrefixCheckboxes from "./mains/PrefixCheckboxes";
// ghosts
import CustomConfirmButton from "./ghosts/CustomConfirmButton";
// others
import styles from "./styles.module.less";

const SwitchPrefix = () => {
  const router = useRouter();
  const { phoneNoPrefix } = router.state || {};
  const [selectedPrefix, setSelectedPrefix] = useState(phoneNoPrefix);

  return (
    <div className={styles.switchPrefixContentWrapper}>
      <PrefixCheckboxes {...{ selectedPrefix, setSelectedPrefix }} />
      <CustomConfirmButton {...{ selectedPrefix, setSelectedPrefix }} />
    </div>
  );
};

export default SwitchPrefix;

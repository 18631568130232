// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const StepItem = ({ label, content }: { label: string; content: string }) => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.stepItemWrapper}>
      <div className={styles.label}>{localeDataSource[label]}</div>
      <div className={styles.content}>{localeDataSource[content]}</div>
    </div>
  );
};
export default StepItem;

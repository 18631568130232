export default {
  "CabinetAssign.receiver.info.heading": "Receiver information",
  "CabinetAssign.receiver.phone.no": "Phone number",
  "CabinetAssign.receiver.name.auto.display": "Receiver name (Auto display)",
  "CabinetAssign.rental.note.heading": "Note",
  "CabinetAssign.rental.note.placeholder": "Description (Optional)",
  "CabinetAssign.rental.notice":
    "CloudLocker is not responsible for the content in each parcel.",
  "CabinetAssign.cabinet.size.heading": "Choose cabinet size",
  "CabinetAssign.select.size.guide":
    "Please select the appropriate size below for your parcel. (size: cm)",
  "CabinetAssign.cabinet.width": "Width",
  "CabinetAssign.cabinet.height": "Height",
  "CabinetAssign.vacancy.qty": "Available",
  "CabinetAssign.send": "Send",
  "CabinetAssign.no.empty.cabinets": "Oops! No vacancy",
  "CabinetAssign.come.back.later": "Please come back later."
};

// libs
import { useEffect } from "react";
import { useFormikContext } from "formik";
// types
import {
  CabinetAssignValues,
  DoGetReceiverName
} from "@/types/screens/CabinetAssign";
// hooks
import { useLocaleContext } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import { formatPhoneNo, showToastMessage } from "@/utils";

const { RECEIVER_NAME, RECEIVER_PHONE_NO } =
  CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;

const GetReceiverName = ({
  triggerGetReceiverName,
  gettingReceiverName,
  doGetReceiverName
}: {
  triggerGetReceiverName: number;
  gettingReceiverName: boolean;
  doGetReceiverName: DoGetReceiverName;
}) => {
  const { errors, setFieldValue, values } =
    useFormikContext<CabinetAssignValues>();
  const { localeDataSource } = useLocaleContext();

  useEffect(() => {
    if (!triggerGetReceiverName || gettingReceiverName) return;

    if (errors[RECEIVER_PHONE_NO]) {
      setFieldValue(RECEIVER_NAME, "");
      return;
    }

    (async () => {
      try {
        const receiverName = await doGetReceiverName({
          phoneNo: formatPhoneNo(values[RECEIVER_PHONE_NO])
        });
        setFieldValue(RECEIVER_NAME, receiverName);
      } catch {
        setFieldValue(RECEIVER_NAME, "");
        showToastMessage(localeDataSource.CABINET_ASSIGN_MSG_E_002);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGetReceiverName]);

  return null;
};

export default GetReceiverName;

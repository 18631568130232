// libs
import classNames from "classnames";
// others
import styles from "./styles.module.less";

const SizeSymbol = ({
  cabinetSize,
  isChosen
}: {
  cabinetSize: string;
  isChosen: boolean;
}) => (
  <div
    className={classNames(styles.sizeSymbolWrapper, {
      [styles.activeSymbol]: isChosen
    })}
  >
    {cabinetSize}
  </div>
);

export default SizeSymbol;

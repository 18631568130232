// types
import { Master } from "@/types/firestore/masters";
// others
import { FirestoreOperation } from "../sdk";

class MasterMixin {
  firestoreOpt: FirestoreOperation;
  static docId = "masterId";

  /**
   * Compose FirestoreOperation
   */
  constructor({ docPath }: Record<string, string>) {
    this.firestoreOpt = new FirestoreOperation({
      docId: MasterMixin.docId,
      docPath
    });
  }

  getMasters() {
    const { getDocument } = this.firestoreOpt.provideQuery();

    return getDocument<Master>();
  }
}

export default MasterMixin;

// hooks
import { useLocaleContext } from "@/hooks";
// components
import AnimatedBanner from "@/components/AnimatedBanner";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const ContainerPreloadBanner = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.containerPreloadWrapper}>
      <AnimatedBanner
        bannerSource={CONSTANTS.ASSET_LINKS.AUTH_INDICATOR_BANNER}
        headingTitle={localeDataSource["ContainerPreload.heading"]}
        subheadingTitle={localeDataSource["ContainerPreload.sub.heading"]}
      />
    </div>
  );
};

export default ContainerPreloadBanner;

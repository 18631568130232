// libs
import { BrowserRouter } from "react-router-dom";
// contexts
import AppContextWrapper from "@/contexts/AppContextWrapper";
// others
import ContainerRoutes from "@/routers/routes/ContainerRoutes";
import styles from "@/App.module.less";
import "@/styles/global.less";

const App = () => (
  <div className={styles.appWrapper}>
    <div className={styles.appWrapperInner}>
      <BrowserRouter>
        <AppContextWrapper>
          <ContainerRoutes />
        </AppContextWrapper>
      </BrowserRouter>
    </div>
  </div>
);

export default App;

// libs
import { useState } from "react";
// types
import { DoSignIn as IDoSignIn } from "@/types/screens/SignInOTP";
// hooks
import { useAuthContext, useLoading, useLocaleContext } from "@/hooks";
// components
import StaticBanner from "@/components/StaticBanner";
import PhoneNo from "./mains/PhoneNo";
import VerificationCode from "./mains/VerificationCode";
import ResendSection from "./mains/ResendSection";
import ChangePhoneNo from "./mains/ChangePhoneNo";
import ProcessIndicator from "@/components/ProcessIndicator";
// ghosts
import GetVerificationId from "./ghosts/GetVerificationId";
import DoSignIn from "./ghosts/DoSignIn";
import RedirectOperation from "./ghosts/RedirectOperation";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { SIGN_IN_OTP_BANNER } = CONSTANTS.ASSET_LINKS;

const SignInOTP = () => {
  const [retryInterval, setRetryInterval] = useState(0);
  const [verificationCode, setVerificationCode] = useState("");
  const [triggerGetVerificationId, setTriggerGetVerificationId] = useState(0);
  const [triggerRedirect, setTriggerRedirect] = useState(0);
  const [triggerSignIn, setTriggerSignIn] = useState(0);
  const { localeDataSource } = useLocaleContext();
  const { signin } = useAuthContext();
  const [doSignIn, doingSignIn] = useLoading<IDoSignIn>(signin);

  return (
    <ProcessIndicator presentation="overlay" isLoading={doingSignIn}>
      <div className={styles.signInOTPWrapper}>
        <StaticBanner
          bannerSource={SIGN_IN_OTP_BANNER}
          headingTitle={localeDataSource["SignInOTP.verify.phoneNo.heading"]}
        />
        <PhoneNo />
        <VerificationCode
          {...{
            verificationCode,
            setVerificationCode,
            setTriggerSignIn
          }}
        />
        <ResendSection
          {...{ retryInterval, setRetryInterval, setTriggerGetVerificationId }}
        />
        <ChangePhoneNo />
        <GetVerificationId
          {...{ setRetryInterval, triggerGetVerificationId }}
        />
        <DoSignIn
          {...{ triggerSignIn, verificationCode, doSignIn, setTriggerRedirect }}
        />
        <RedirectOperation {...{ triggerRedirect }} />
      </div>
    </ProcessIndicator>
  );
};

export default SignInOTP;

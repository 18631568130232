// types
import { SendingStep } from "@/types/screens/SendingProcess";
// components
import StepItem from "../../components/StepItem";
// others
import styles from "./styles.module.less";

const OperationGuideSteps = ({
  implementationProcess
}: {
  implementationProcess: SendingStep[];
}) => (
  <div className={styles.operationGuideStepsWrapper}>
    {implementationProcess.map(({ label, content }) => (
      <StepItem key={label} {...{ label, content }} />
    ))}
  </div>
);

export default OperationGuideSteps;

// libs
import { orderBy } from "firebase/firestore";
// types
import { Cabinet } from "@/types/firestore/cabinets";
import { AutoAssign, OpenCabinet } from "@/types/serverless/mixins/cabinets";
// others
import CONSTANTS from "@/constants";
import {
  FirestoreOperation,
  DatabaseOperation,
  FunctionsOperation
} from "../sdk";

const { LOCKER_ID } = CONSTANTS.COLLECTION_FIELD_NAMES.LOCKERS;
const { CABINET_NO, DISPLAY_ORDER, CABINET_SIZE } =
  CONSTANTS.COLLECTION_FIELD_NAMES.CABINETS;
const { AUTO_ASSIGN_CABINET, OPEN_CABINET } = CONSTANTS.FUNCTION_NAMES;
const {
  doorStatusMapping,
  DOOR_STATUS: { OPEN, CLOSE }
} = CONSTANTS.MAPPINGS.DOOR_STATUS;

class CabinetMixin {
  firestoreOpt: FirestoreOperation;
  databaseOpt: DatabaseOperation;

  static docId: string = CABINET_NO;

  /**
   * Compose FirestoreOperation and DatabaseOperation
   */
  constructor({ collectionPath, docPath }: Record<string, string>) {
    this.firestoreOpt = new FirestoreOperation({
      docId: CabinetMixin.docId,
      collectionPath
    });
    this.databaseOpt = new DatabaseOperation({ docPath });
  }

  getCabinets() {
    const { getDocumentsByCollection } = this.firestoreOpt.provideQuery();
    const constraints = [orderBy(DISPLAY_ORDER)];

    return getDocumentsByCollection<Cabinet>(constraints);
  }

  static async autoAssign({ lockerId, cabinetSize }: AutoAssign.Params) {
    const { data } = await FunctionsOperation.callable<
      AutoAssign.Params,
      AutoAssign.Result
    >(AUTO_ASSIGN_CABINET)({
      [LOCKER_ID]: lockerId,
      [CABINET_SIZE]: cabinetSize
    });

    const { cabinetNo } = data || {};

    return cabinetNo;
  }

  static async openCabinet({ lockerId, cabinetNo }: OpenCabinet.Params) {
    return FunctionsOperation.callable<OpenCabinet.Params, null>(OPEN_CABINET)({
      [LOCKER_ID]: lockerId,
      [CABINET_NO]: cabinetNo
    });
  }

  waitDoorOpen() {
    return new Promise((resolve) => {
      const { listenDataChanges } = this.databaseOpt.provideQuery();
      const unsubscribe = listenDataChanges((doorStatusSnapshot) => {
        if (doorStatusSnapshot === doorStatusMapping[OPEN]) {
          unsubscribe();
          resolve(null);
        }
      });
    });
  }

  waitDoorClose() {
    return new Promise((resolve) => {
      const { listenDataChanges } = this.databaseOpt.provideQuery();
      // TODO: Learn cast type for mapping
      const unsubscribe = listenDataChanges((doorStatusSnapshot) => {
        if (doorStatusSnapshot === doorStatusMapping[CLOSE]) {
          unsubscribe();
          resolve(null);
        }
      });
    });
  }
}

export default CabinetMixin;

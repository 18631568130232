// types
import { RouteStack } from "@/types/route";
// components
import BarcodeScanner from "@/screens/BarcodeScanner";
import SignIn from "@/screens/SignIn";
import SignInOTP from "@/screens/SignInOTP";
import WelcomeNewUser from "@/screens/WelcomeNewUser";
import Home from "@/screens/Home";
import MainRoutes from "./routes/MainRoutes";
// others
import CONSTANTS from "@/constants";

const appStackScreens: RouteStack[] = [
  {
    path: CONSTANTS.ROUTERS.BARCODE_SCANNER,
    name: CONSTANTS.SCREEN_NAMES.BARCODE_SCANNER,
    element: BarcodeScanner
  },
  {
    path: CONSTANTS.ROUTERS.SIGN_IN,
    name: CONSTANTS.SCREEN_NAMES.SIGN_IN,
    element: SignIn
  },
  {
    path: CONSTANTS.ROUTERS.SIGN_IN_OTP,
    name: CONSTANTS.SCREEN_NAMES.SIGN_IN_OTP,
    element: SignInOTP
  },
  {
    path: CONSTANTS.ROUTERS.WELCOME_NEW_USER,
    name: CONSTANTS.SCREEN_NAMES.WELCOME_NEW_USER,
    element: WelcomeNewUser
  },
  {
    path: CONSTANTS.ROUTERS.HOME,
    name: CONSTANTS.SCREEN_NAMES.HOME,
    element: Home
  },
  {
    name: "Main",
    element: MainRoutes,
    path: "*"
  }
];

export default appStackScreens;

// components
import TextAvatar from "../TextAvatar";
import DisplayName from "../DisplayName";
// others
import styles from "./styles.module.less";

const UserInfo = () => (
  <div className={styles.userInfoWrapper}>
    <TextAvatar />
    <DisplayName />
  </div>
);

export default UserInfo;

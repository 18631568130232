// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const ResendMessage = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.resendMessageWrapper}>
      {localeDataSource["SignInOTP.did.not.receive.code"]}
    </div>
  );
};

export default ResendMessage;

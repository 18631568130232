// libs
import { Input } from "antd-mobile";
import { useFormikContext } from "formik";
// types
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
// hooks
import { useLocaleContext } from "@/hooks";
// components
import MatIcon from "@/components/MatIcon";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { RECEIVER_NAME } = CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;

const ReceiverName = () => {
  const { localeDataSource } = useLocaleContext();
  const { values } = useFormikContext<CabinetAssignValues>();

  return (
    <div className={styles.receiverNameWrapper}>
      <MatIcon name="mdi-account-circle" size="20px" />
      <Input
        disabled
        placeholder={
          localeDataSource["CabinetAssign.receiver.name.auto.display"]
        }
        className={styles.inputReceiverName}
        value={values[RECEIVER_NAME]}
      />
    </div>
  );
};
export default ReceiverName;

// libs
import { createContext, useMemo } from "react";
// types
import { LocaleContextValue, LocaleContextProvider } from "@/types/context";
// hooks
import useProvideLocale from "@/hooks/useProvideLocale";
// others
import locales from "@/locales";

export const LocaleContext = createContext<LocaleContextValue>(
  {} as LocaleContextValue
);

export const ProvideLocale: LocaleContextProvider = ({ value, children }) => {
  const { locale, setLocale } = useProvideLocale();

  return (
    <LocaleContext.Provider
      value={
        value ||
        useMemo(
          () => ({ locale, setLocale, localeDataSource: locales[locale] }),
          [locale, setLocale]
        )
      }
    >
      {children}
    </LocaleContext.Provider>
  );
};

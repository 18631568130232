// components
import OperationItem from "../../components/OperationItem";
// dataSources
import { deliveryOperationList } from "@/dataSources/Home";
// others
import styles from "./styles.module.less";

const DeliverySection = () => (
  <div className={styles.deliverySectionWrapper}>
    {deliveryOperationList.map(({ label, iconName, screenPath }) => (
      <OperationItem key={label} {...{ label, iconName, screenPath }} />
    ))}
  </div>
);

export default DeliverySection;

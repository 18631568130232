// components
import CancelButton from "../CancelButton";
import ConfirmButton from "../ConfirmButton";
// others
import styles from "./styles.module.less";

const ModalFooter = () => (
  <div className={styles.modalFooterWrapper}>
    <ConfirmButton />
    <CancelButton />
  </div>
);

export default ModalFooter;

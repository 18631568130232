// libs
import { useEffect } from "react";
import { useFormikContext } from "formik";
// types
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
// hooks
import { useAuthContext, useDefaultRentalNote, useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import { formatPhoneNo } from "@/utils";

const { SENDING_PROCESS } = CONSTANTS.ROUTERS;
const {
  CABINET_NO,
  CABINET_SIZE,
  RECEIVER_NAME,
  RECEIVER_PHONE_NO,
  RENTAL_NOTE,
  LOCKER_ID,
  LOCKER_ADDRESS,
  LOCKER_NAME,
  LOCKER_NO,
  SENDER_NAME,
  SENDER_PHONE_NO
} = CONSTANTS.COLLECTION_FIELD_NAMES.RENTALS;

const RedirectSendingProcess = ({
  assignedCabinetNo,
  triggerRedirectProcess
}: {
  assignedCabinetNo: string;
  triggerRedirectProcess: number;
}) => {
  const router = useRouter();
  const { lockerInfo } = router.state || {};
  const { values } = useFormikContext<CabinetAssignValues>();
  const { defaultRentalNote } = useDefaultRentalNote();
  const { user } = useAuthContext();
  const { displayName, phoneNumber } = user || {};

  const receiverPhoneNo = formatPhoneNo(values[RECEIVER_PHONE_NO]);

  const rentalInfo = {
    [LOCKER_ID]: lockerInfo[LOCKER_ID],
    [LOCKER_ADDRESS]: lockerInfo[LOCKER_ADDRESS],
    [LOCKER_NAME]: lockerInfo[LOCKER_NAME],
    [LOCKER_NO]: lockerInfo[LOCKER_NO],
    [CABINET_NO]: assignedCabinetNo,
    [CABINET_SIZE]: values[CABINET_SIZE],
    [RECEIVER_NAME]: values[RECEIVER_NAME],
    [RECEIVER_PHONE_NO]: receiverPhoneNo,
    [RENTAL_NOTE]: defaultRentalNote,
    [SENDER_NAME]: displayName,
    [SENDER_PHONE_NO]: phoneNumber
  };

  useEffect(
    () => {
      if (!triggerRedirectProcess) return;

      router.replace(SENDING_PROCESS, { state: { rentalInfo } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerRedirectProcess]
  );

  return null;
};

export default RedirectSendingProcess;

// types
import { SetState } from "@/types/common";
// components
import QrcodeScanner from "@/components/QrCodeScanner";
// others
import styles from "./styles.module.less";

const CameraBlock = ({
  setScanned,
  handleBarcodeScanned
}: {
  setScanned: SetState<boolean>;
  handleBarcodeScanned: (data: string) => void;
}) => (
  <div className={styles.scanningBlockWrapper}>
    <QrcodeScanner {...{ handleBarcodeScanned, setScanned }} />
  </div>
);

export default CameraBlock;

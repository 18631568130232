// libs
import { Image, Skeleton } from "antd-mobile";

const ImageWithSkeleton = ({
  src,
  alt,
  className,
  width,
  height
}: {
  src: string;
  alt: string;
  className?: string;
  width?: number | string;
  height: number | string;
}) => (
  <Image
    {...{ src, alt, className, width, height }}
    fit="contain"
    placeholder={<Skeleton animated style={{ width, height }} />}
  />
);

export default ImageWithSkeleton;

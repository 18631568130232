// libs
import { DependencyList, EffectCallback, useEffect } from "react";
// hooks
import useIsFirstRender from "./useIsFirstRender";

function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const isMounted = useIsFirstRender();

  useEffect(() => {
    if (!isMounted) return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useUpdateEffect;

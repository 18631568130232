// libs
import { Button } from "antd-mobile";
import { Link } from "react-router-dom";
// hooks
import { useRouter } from "@/hooks";
// components
import MatIcon from "@/components/MatIcon";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { SWITCH_LOCALE } = CONSTANTS.ROUTERS;

const SwitchLocaleControl = () => {
  const router = useRouter();

  return (
    <Link to={SWITCH_LOCALE} state={{ background: router.location }}>
      <Button className={styles.switchLocaleControlWrapper} fill="solid">
        <MatIcon name="mdi-translate" size="20px" />
      </Button>
    </Link>
  );
};

export default SwitchLocaleControl;

// components
import ImageWithSkeleton from "@/components/ImageWithSkeleton";
// others
import styles from "./styles.module.less";

const AppStoreSection = () => (
  <div className={styles.appStoreSectionWrapper}>
    <a target="blank" href="https://www.apple.com/app-store/">
      <ImageWithSkeleton
        src="/assets/images/Home/home_apple_store.svg"
        alt="app-store-section"
        className={styles.appStoreSectionWrapper}
        height={60}
      />
    </a>
  </div>
);

export default AppStoreSection;

// libs
import { PatternFormat } from "react-number-format";
// others
import CONSTANTS from "@/constants";

const PhoneNoFormatter = ({
  prefix = "",
  phoneNo
}: {
  prefix: string;
  phoneNo: number | string;
}) => (
  <PatternFormat
    displayType="text"
    value={phoneNo}
    format={`${prefix}${CONSTANTS.PHONE_NO_FORMAT}`}
    renderText={(value) => <p>{value}</p>}
  />
);

export default PhoneNoFormatter;

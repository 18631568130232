// hooks
import { useLocaleContext } from "@/hooks";
// components
import AnimatedBanner from "@/components/AnimatedBanner";
import BackHomeButton from "@/components/BackHomeButton";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const SendingComplete = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.sendingCompleteWrapper}>
      <AnimatedBanner
        bannerSource={CONSTANTS.ASSET_LINKS.SENDING_COMPLETE_BANNER}
        headingTitle={localeDataSource["SendingComplete.heading"]}
        subheadingTitle={localeDataSource["Common.complete.subhead"]}
      />
      <BackHomeButton />
    </div>
  );
};

export default SendingComplete;

// libs
import type { PropsWithChildren } from "react";
// types
import {
  AuthContextValue,
  LocaleContextValue,
  MasterDataContextValue
} from "@/types/context";
// contexts
import { ProvideLocale } from "./ProvideLocale";
import { ProvideAuth } from "./ProvideAuth";
import { ProvideMasterData } from "./ProvideMasterData";

type AppContextValue = LocaleContextValue &
  AuthContextValue &
  MasterDataContextValue;

const AppContextWrapper = ({
  appContextMock = {},
  children
}: PropsWithChildren<{ appContextMock?: Record<string, AppContextValue> }>) => {
  const providers = [ProvideLocale, ProvideAuth, ProvideMasterData];

  return providers.reduce(
    (accumulator, Provider) => (
      <Provider value={appContextMock[Provider.name]}>{accumulator}</Provider>
    ),
    children
  ) as React.ReactElement;
};

export default AppContextWrapper;

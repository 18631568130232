// types
import { WelcomeNewUserValues } from "@/types/screens/WelcomeNewUser";
// others
import CONSTANTS from "@/constants";

const { DISPLAY_NAME } = CONSTANTS.SCREEN_FIELD_NAMES.WELCOME_NEW_USER;

const welcomeNewUserFormData = {
  initialValues: { [DISPLAY_NAME]: "" } as WelcomeNewUserValues
};

export default welcomeNewUserFormData;

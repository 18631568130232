// others
import CONSTANTS from "@/constants";

export default {
  [CONSTANTS.SCREEN_NAMES.SPLASH]: "Splash",
  [CONSTANTS.SCREEN_NAMES.SIGN_IN]: "Sign In",
  [CONSTANTS.SCREEN_NAMES.SIGN_IN_OTP]: "Phone Authentication",
  [CONSTANTS.SCREEN_NAMES.WELCOME_NEW_USER]: "Welcome New User",
  [CONSTANTS.SCREEN_NAMES.HOME]: "Home",
  [CONSTANTS.SCREEN_NAMES.BARCODE_SCANNER]: "Scan QR",
  [CONSTANTS.SCREEN_NAMES.CABINET_ASSIGN]: "Cabinet Assign",
  [CONSTANTS.SCREEN_NAMES.SENDING_PROCESS]: "Sending Process",
  [CONSTANTS.SCREEN_NAMES.SENDING_COMPLETE]: "Sending Complete",
  [CONSTANTS.SCREEN_NAMES.SIGN_OUT]: "Sign Out",
  [CONSTANTS.SCREEN_NAMES.SWITCH_LOCALE]: "Choose Language",
  [CONSTANTS.SCREEN_NAMES.SWITCH_PREFIX]: "Choose Country Code"
};

// types
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
// others
import CONSTANTS from "@/constants";

const { RECEIVER_PHONE_NO, RECEIVER_NAME, RENTAL_NOTE, CABINET_SIZE } =
  CONSTANTS.SCREEN_FIELD_NAMES.CABINET_ASSIGN;

const cabinetAssignFormData = () => ({
  initialValues: {
    [RECEIVER_PHONE_NO]: "",
    [RECEIVER_NAME]: "",
    [RENTAL_NOTE]: "",
    [CABINET_SIZE]: ""
  } as CabinetAssignValues
});

export default cabinetAssignFormData;

export default {
  "CabinetAssign.receiver.info.heading": "Thông tin người nhận",
  "CabinetAssign.receiver.phone.no": "Số điện thoại",
  "CabinetAssign.receiver.name.auto.display":
    "Tên người nhận (Tự động hiển thị)",
  "CabinetAssign.rental.note.heading": "Ghi chú",
  "CabinetAssign.rental.note.placeholder": "Nội dung (Không bắt buộc)",
  "CabinetAssign.rental.notice":
    "CloudLocker không chịu trách nhiệm về nội dung trong mỗi gói hàng.",
  "CabinetAssign.cabinet.size.heading": "Chọn kích thước ô tủ",
  "CabinetAssign.select.size.guide":
    "Vui lòng chọn kích thước ô tủ phù hợp với kiện hàng của bạn. (đơn vị: cm)",
  "CabinetAssign.cabinet.width": "Rộng",
  "CabinetAssign.cabinet.height": "Cao",
  "CabinetAssign.vacancy.qty": "Còn trống",
  "CabinetAssign.send": "Gửi hàng",
  "CabinetAssign.no.empty.cabinets": "Oops! Hết ô tủ trống",
  "CabinetAssign.come.back.later": "Vui lòng quay lại sau."
};

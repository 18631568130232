// components
import ImageWithSkeleton from "@/components/ImageWithSkeleton";
// others
import styles from "./styles.module.less";

const AppLogo = () => (
  <ImageWithSkeleton
    src="/assets/images/Home/home_logo_no_branding.png"
    alt="app-logo"
    className={styles.appLogoWrapper}
    height={100}
    width={250}
  />
);

export default AppLogo;

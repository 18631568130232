enum RENTALS {
  CABINET_NO = "cabinetNo",
  CABINET_SIZE = "cabinetSize",
  LOCKER_ID = "lockerId",
  LOCKER_NAME = "lockerName",
  LOCKER_ADDRESS = "lockerAddress",
  LOCKER_NO = "lockerNo",
  RECEIVER_NAME = "receiverName",
  RECEIVER_PHONE_NO = "receiverPhoneNo",
  RENTAL_NOTE = "rentalNote",
  SENDER_NAME = "senderName",
  SENDER_PHONE_NO = "senderPhoneNo",
  RENTAL_ID = "rentalId"
}

export default RENTALS;

// libs
import { NavBar } from "antd-mobile";
// hooks
import { useRouter } from "@/hooks";
// components
import ArrowLeftIcon from "./mains/ArrowLeftIcon";
// others
import { formatLockerName } from "@/utils";
import styles from "./styles.module.less";

const LockerHeaderTitle = () => {
  const router = useRouter();
  const { lockerInfo } = router.state || {};
  const { lockerNo, lockerName } = lockerInfo;

  return (
    <NavBar
      onBack={() => router.goBack()}
      className={styles.lockerHeaderTitleWrapper}
      backArrow={<ArrowLeftIcon />}
    >
      {formatLockerName(lockerNo, lockerName)}
    </NavBar>
  );
};

export default LockerHeaderTitle;

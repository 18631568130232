// libs
import { useState, useEffect } from "react";
// types
import { Master } from "@/types/firestore/masters";
// serverless
import { MasterOperation } from "@/serverless";

const useProvideMasterData = () => {
  const [masterData, setMasterData] = useState<Master>();

  useEffect(() => {
    // TODO: handle getMasters twice
    (async () => {
      try {
        const masterOpt = new MasterOperation();
        const { data: masters } = await masterOpt.getMasters();

        setMasterData(masters);
      } catch {
        setMasterData({} as Master);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return masterData;
};

export default useProvideMasterData;

// types
import { SetState } from "@/types/common";
// hooks
import { useCustomConfirmModal } from "@/hooks";

const CustomConfirmButton = ({
  setShowParcelNotFitModal,
  setShowConfirmCancelModal
}: {
  setShowParcelNotFitModal: SetState<boolean>;
  setShowConfirmCancelModal: SetState<boolean>;
}) => {
  useCustomConfirmModal(() => {
    setShowParcelNotFitModal(false);
    setShowConfirmCancelModal(true);
  }, []);

  return null;
};

export default CustomConfirmButton;

// libs
import { createContext } from "react";
// types
import {
  MasterDataContextProvider,
  MasterDataContextValue
} from "@/types/context";
// hooks
import useProvideMasterData from "@/hooks/useProvideMasterData";

export const MasterDataContext = createContext<MasterDataContextValue>(
  {} as MasterDataContextValue
);

export const ProvideMasterData: MasterDataContextProvider = ({
  value,
  children
}) => {
  const masterData = useProvideMasterData();

  return (
    <MasterDataContext.Provider value={value || masterData}>
      {children}
    </MasterDataContext.Provider>
  );
};

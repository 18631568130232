// libs
import { SpinLoading } from "antd-mobile";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { AZURE_RADIANCE } = CONSTANTS.COLORS;

const CardIndicator = () => (
  <div className={styles.cardIndicatorWrapper}>
    <SpinLoading color={AZURE_RADIANCE} />
  </div>
);

export default CardIndicator;

// libs
import { useEffect } from "react";
import { RecaptchaVerifier } from "firebase/auth";
// others
import { FIREBASE_AUTH } from "@/firebaseConfig";

const FirebaseRecaptchaVerifier = () => {
  useEffect(() => {
    const recaptchaVerifier = document.getElementById("firebase-recaptcha");
    if (!recaptchaVerifier) return;

    window.recaptchaVerifier = new RecaptchaVerifier(
      recaptchaVerifier,
      {
        size: "invisible"
      },
      FIREBASE_AUTH
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="firebase-recaptcha" />;
};

export default FirebaseRecaptchaVerifier;

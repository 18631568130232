// others
import styles from "./styles.module.less";

const CabinetSize = ({ cabinetSize }: { cabinetSize: string }) => (
  <div className={styles.cabinetSizeWrapper}>
    <div className={styles.cabinetSizeWrapperInner}>
      <h2 className={styles.cabinetSize}>{cabinetSize}</h2>
    </div>
  </div>
);

export default CabinetSize;

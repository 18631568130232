// libs
import { Toast } from "antd-mobile";
import type { RecaptchaVerifier } from "firebase/auth";
// hooks
import { useLocaleContext, useUpdateEffect } from "@/hooks";
// components
import FirebaseRecaptchaVerifier from "../FirebaseRecaptchaVerifier";

const FirebaseVerifier = ({
  formattedPhoneNo,
  getVerificationId,
  triggerGetVerificationId,
  verifiedCallback
}: {
  formattedPhoneNo: string;
  getVerificationId: (
    phoneNo: string,
    recaptchaVerifier: RecaptchaVerifier
  ) => Promise<string>;
  triggerGetVerificationId: number;
  verifiedCallback: (verificationId: string) => void;
}) => {
  const { localeDataSource } = useLocaleContext();

  useUpdateEffect(() => {
    if (!triggerGetVerificationId) return;

    (async () => {
      try {
        const verificationId = await getVerificationId(
          formattedPhoneNo,
          window.recaptchaVerifier
        );
        if (verificationId) verifiedCallback(verificationId);
      } catch (error) {
        const { code } = error as { code: string };
        Toast.show({
          content: localeDataSource[code] || localeDataSource.COMMON_MSG_E_004
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerGetVerificationId]);

  return <FirebaseRecaptchaVerifier />;
};

export default FirebaseVerifier;

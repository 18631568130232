// components
import UserGreeting from "./mains/UserGreeting";
import ServiceSection from "@/components/Home/mains/ServiceSection";
import DeliverySection from "@/components/Home/mains/DeliverySection";
import CopyRightSection from "@/components/Home/mains/CopyRightSection";
// others
import styles from "./styles.module.less";

const AuthHome = () => (
  <div className={styles.authHomeWrapper}>
    <div className={styles.authHomeWrapperInner}>
      <UserGreeting />
      <ServiceSection />
      <DeliverySection />
    </div>
    <CopyRightSection />
  </div>
);

export default AuthHome;

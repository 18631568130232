// others
import CONSTANTS from "@/constants";

export default {
  [CONSTANTS.SCREEN_NAMES.SPLASH]: "Màn hình chờ",
  [CONSTANTS.SCREEN_NAMES.SIGN_IN]: "Đăng nhập",
  [CONSTANTS.SCREEN_NAMES.SIGN_IN_OTP]: "Xác minh số điện thoại",
  [CONSTANTS.SCREEN_NAMES.WELCOME_NEW_USER]: "Chào đón người dùng mới",
  [CONSTANTS.SCREEN_NAMES.HOME]: "Trang chủ",
  [CONSTANTS.SCREEN_NAMES.BARCODE_SCANNER]: "Quét mã QR",
  [CONSTANTS.SCREEN_NAMES.CABINET_ASSIGN]: "Lựa chọn ô tủ",
  [CONSTANTS.SCREEN_NAMES.SENDING_PROCESS]: "Tiến hành gửi hàng",
  [CONSTANTS.SCREEN_NAMES.SENDING_COMPLETE]: "Gửi hàng thành công",
  [CONSTANTS.SCREEN_NAMES.SIGN_OUT]: "Đăng xuất",
  [CONSTANTS.SCREEN_NAMES.SWITCH_LOCALE]: "Đổi ngôn ngữ",
  [CONSTANTS.SCREEN_NAMES.SWITCH_PREFIX]: "Đổi mã vùng"
};

import common from "./common";
import Home from "./Home";
import SignIn from "./SignIn";
import SignInOTP from "./SignInOTP";
import WelcomeNewUser from "./WelcomeNewUser";
import CabinetAssign from "./CabinetAssign";
import SendingComplete from "./SendingComplete";
import SendingProcess from "./SendingProcess";
import SignOut from "./SignOut";
import ContainerPreload from "./ContainerPreload";
import BarcodeScanner from "./BarcodeScanner";

export default {
  ...common,
  ...Home,
  ...SignIn,
  ...SignInOTP,
  ...WelcomeNewUser,
  ...CabinetAssign,
  ...SendingComplete,
  ...SendingProcess,
  ...SignOut,
  ...ContainerPreload,
  ...BarcodeScanner
};

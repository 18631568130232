// types
import { RouteStack } from "@/types/route";
// components
import CabinetAssign from "@/screens/CabinetAssign";
import SendingProcess from "@/screens/SendingProcess";
import SendingComplete from "@/screens/SendingComplete";
// others
import CONSTANTS from "@/constants";

const mainStackScreens: RouteStack[] = [
  {
    path: CONSTANTS.ROUTERS.CABINET_ASSIGN,
    name: CONSTANTS.SCREEN_NAMES.CABINET_ASSIGN,
    element: CabinetAssign
  },
  {
    path: CONSTANTS.ROUTERS.SENDING_COMPLETE,
    name: CONSTANTS.SCREEN_NAMES.SENDING_COMPLETE,
    element: SendingComplete
  },
  {
    path: CONSTANTS.ROUTERS.SENDING_PROCESS,
    name: CONSTANTS.SCREEN_NAMES.SENDING_PROCESS,
    element: SendingProcess
  }
];

export default mainStackScreens;

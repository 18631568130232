// libs
import { useEffect, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import {
  Html5QrcodeSupportedFormats,
  QrcodeErrorCallback,
  QrcodeSuccessCallback
} from "html5-qrcode/esm/core";
// types
import { SetState } from "@/types/common";
// others
import CONSTANTS from "@/constants";

const { CAMERA_FPS, PREFER_CAMERA } = CONSTANTS.CAMERA_CONFIG;

const initializeCamera = (elementId: string): Html5Qrcode =>
  new Html5Qrcode(elementId, {
    formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    verbose: false
  });

const startScanning = ({
  camera,
  handleBarcodeScanned,
  handleScannedError,
  setScanned
}: {
  camera: Html5Qrcode;
  handleBarcodeScanned: QrcodeSuccessCallback;
  handleScannedError?: QrcodeErrorCallback;
  setScanned: SetState<boolean>;
}) =>
  // CameraConfig
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
  camera
    .start(
      { facingMode: PREFER_CAMERA },
      {
        fps: CAMERA_FPS
      },
      handleBarcodeScanned,
      handleScannedError
    )
    .then(() => setScanned(true));

const useCameraPlugin = ({
  elementId,
  setScanned,
  handleBarcodeScanned,
  handleScannedError
}: {
  elementId: string;
  setScanned: SetState<boolean>;
  handleBarcodeScanned: (data: string) => void;
  handleScannedError?: QrcodeErrorCallback;
}) => {
  const cameraScanner = useRef<Html5Qrcode>();

  useEffect(() => {
    if (!cameraScanner.current) {
      const initialCamera = initializeCamera(elementId);
      cameraScanner.current = initialCamera;
      startScanning({
        camera: initialCamera,
        handleBarcodeScanned,
        handleScannedError,
        setScanned
      });
    }

    return () => {
      // Force to stop camera when Cancel and Reload page
      // html5-qrcode Library would throw an error
      // when the camera's state isn't scanning
      try {
        cameraScanner.current
          ?.stop()
          .then(() => cameraScanner.current?.clear());
      } catch (error) {
        //
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId]);
};

export default useCameraPlugin;

// libs
import { SpinLoading, Mask } from "antd-mobile";
// hooks
import { useLocaleContext } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { AZURE_RADIANCE } = CONSTANTS.COLORS;

const OverlayIndicator = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <Mask visible>
      <div className={styles.overlayIndicatorWrapper}>
        <SpinLoading color={AZURE_RADIANCE} />
        <div className={styles.overlayIndicatorText}>
          {localeDataSource["Common.process.indicator"]}
        </div>
      </div>
    </Mask>
  );
};

export default OverlayIndicator;

// hooks
import { useAuthorized, useRouter, useUpdateEffect } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { WELCOME_NEW_USER, CABINET_ASSIGN } = CONSTANTS.ROUTERS;

const RedirectOperation = ({
  triggerRedirect
}: {
  triggerRedirect: number;
}) => {
  const { authenticatedReturningUser } = useAuthorized();
  const router = useRouter();
  const { lockerInfo } = router.state || {};

  useUpdateEffect(() => {
    if (!triggerRedirect) return;

    const comingPath = authenticatedReturningUser
      ? CABINET_ASSIGN
      : WELCOME_NEW_USER;

    router.replace(comingPath, { state: { lockerInfo } });
  }, [triggerRedirect]);
  return null;
};

export default RedirectOperation;

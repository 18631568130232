// hooks
import { useAuthContext, useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const DisplayName = () => {
  const { localeDataSource } = useLocaleContext();
  const { user } = useAuthContext();
  const { displayName } = user || {};

  return (
    <div className={styles.displayNameWrapper}>
      <div className={styles.greeting}>{localeDataSource["Home.hello"]}</div>
      <div className={styles.displayName}>{displayName}</div>
    </div>
  );
};

export default DisplayName;

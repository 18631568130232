// hooks
import useLocalStorage from "./useLocalStorage";
// others
import CONSTANTS from "@/constants";

const { VI_VN } = CONSTANTS.LOCALE;
const { LOCALE } = CONSTANTS.LOCAL_STORAGE_KEY;

const useProvideLocale = () => {
  const [locale, setLocale] = useLocalStorage<string>(LOCALE, VI_VN);

  return {
    locale: locale || VI_VN,
    setLocale
  };
};

export default useProvideLocale;

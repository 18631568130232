enum RENTAL_OPERATION {
  SENDING = "sending"
}

const rentalOperationMapping = {
  [RENTAL_OPERATION.SENDING]: "0",
  0: RENTAL_OPERATION.SENDING
} as const;

export default { RENTAL_OPERATION, rentalOperationMapping };

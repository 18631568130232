// components
import ScanningGuide from "../ScanningGuide";
import ScanningBorder from "../ScanningBorder";
import ScanningRedLine from "../ScanningRedLine";
// others
import styles from "./styles.module.less";

const ScanningBlock = () => (
  <div className={styles.scanningBlockWrapper}>
    <ScanningGuide />
    <ScanningBorder />
    <ScanningRedLine />
  </div>
);

export default ScanningBlock;

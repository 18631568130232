const MatIcon = ({
  name,
  color,
  size = "25px"
}: {
  name: string;
  size?: string;
  color?: string;
}) => {
  const iconName = `mdi ${name}`;

  return <i className={iconName} style={{ fontSize: size, color }} />;
};

export default MatIcon;

// libs
import { useState } from "react";
// hooks
import useRouter from "./useRouter";
// types
import { CallbackVoid } from "@/types/common";

const useProvideModal = () => {
  const router = useRouter();
  const { background } = router.state || {};

  const defaultOnCancel = () => {
    if (background) router.goBack();
  };

  const [onCancel, setOnCancel] = useState<CallbackVoid>(() => defaultOnCancel);
  const [onOk, setOnOk] = useState<CallbackVoid>(() => defaultOnCancel);

  return {
    onCancel,
    setOnCancel,
    onOk,
    setOnOk
  };
};

export default useProvideModal;

// components
import SwitchLocaleControl from "@/components/Home/components/SwitchLocaleControl";
import LogOutButton from "../../components/LogOutButton";
// others
import styles from "./styles.module.less";

const ControlButtons = () => (
  <div className={styles.controlButtonsWrapper}>
    <SwitchLocaleControl />
    <LogOutButton />
  </div>
);

export default ControlButtons;

// libs
import { ReactNode } from "react";
// components
import BannerHeading from "./mains/BannerHeading";
import BannerSubheading from "./mains/BannerSubheading";
// others
import styles from "./styles.module.less";

const BannerWithHeading = ({
  headingTitle,
  subheadingTitle,
  children
}: {
  headingTitle: string;
  subheadingTitle?: string;
  children: ReactNode;
}) => (
  <div className={styles.bannerWithHeadingWrapper}>
    {children}
    <BannerHeading {...{ headingTitle }} />
    {subheadingTitle && <BannerSubheading {...{ subheadingTitle }} />}
  </div>
);

export default BannerWithHeading;

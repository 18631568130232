// others
import styles from "./styles.module.less";

const ScanningBorder = () => (
  <div className={styles.scanningBorderWrapper}>
    <div className={styles.scanningBorderWrapperInner}>
      <div className={styles.scanningBorderTopLeft} />
      <div className={styles.scanningBorderTopRight} />
      <div className={styles.scanningBorderBottomLeft} />
      <div className={styles.scanningBorderBottomRight} />
    </div>
  </div>
);

export default ScanningBorder;

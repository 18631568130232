// libs
import { PropsWithChildren } from "react";
// components
import CardIndicator from "./mains/CardIndicator";
import OverlayIndicator from "./mains/OverlayIndicator";

enum PRESENTATION_OPTIONS {
  CARD = "card",
  OVERLAY = "overlay"
}

/**
 * reference: https://devblogs.microsoft.com/typescript/announcing-typescript-4-1/#template-literal-types
 * example: https://bobbyhadz.com/blog/typescript-convert-enum-to-union
 */
type PRESENTATION_OPTIONS_UNION = `${PRESENTATION_OPTIONS}`;

const ProcessIndicator = ({
  children,
  isLoading,
  presentation = PRESENTATION_OPTIONS.CARD
}: PropsWithChildren<{
  isLoading: boolean;
  presentation: PRESENTATION_OPTIONS_UNION;
}>) => {
  // TODO: Better logic due to children render different between react v16 vs v18
  if (isLoading && presentation === PRESENTATION_OPTIONS.CARD)
    return <CardIndicator />;

  return (
    <>
      {isLoading && <OverlayIndicator />}
      {children}
    </>
  );
};

export default ProcessIndicator;

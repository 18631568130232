// libs
import { Card } from "antd-mobile";
// hooks
import { useLocaleContext, useRouter } from "@/hooks";
// components
import MatIcon from "@/components/MatIcon";
// others
import styles from "./styles.module.less";

const OperationItem = ({
  label,
  iconName,
  screenPath
}: {
  label: string;
  iconName: string;
  screenPath: string;
}) => {
  const router = useRouter();
  const { localeDataSource } = useLocaleContext();

  return (
    <Card
      className={styles.operationItemWrapper}
      onClick={() => router.navigate(screenPath)}
    >
      <div className={styles.iconWrapper}>
        <div className={styles.iconWrapperInner}>
          <MatIcon name={iconName} size="30px" />
        </div>
      </div>
      <h3 className={styles.operationItemContent}>{localeDataSource[label]}</h3>
    </Card>
  );
};

export default OperationItem;

// name that color: https://chir.ag/projects/name-that-color/#6195ED
export default {
  WHITE: "#FFFFFF",
  AZURE_RADIANCE: "#0D7EDF",
  BLACK: "#000000BF",
  IRON: "#DFE0E2",
  RED_ORANGE: "#FC2626",
  TROPICAL_BLUE: "#DBEBFA",
  GRAY: "#8C8C8C",
  ATHENS_GRAY: "#F1F2F7"
};

// libs
import { Routes, Route } from "react-router-dom";
// hooks
import { useLocaleContext, useRouter } from "@/hooks";
// components
import CustomModal from "@/components/CustomModal";
// others
import childStackScreens from "../childStackScreens";

const ChildRoutes = () => {
  const router = useRouter();
  const { state } = router;
  const { localeDataSource } = useLocaleContext();

  return (
    state?.background && (
      <Routes>
        {childStackScreens.map(({ name, element: Element, path, title }) => (
          <Route
            key={name}
            element={
              <CustomModal isVisible title={title || localeDataSource[name]}>
                <Element />
              </CustomModal>
            }
            path={path}
          />
        ))}
      </Routes>
    )
  );
};

export default ChildRoutes;

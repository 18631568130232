// hooks
import { useModalContext } from "@/hooks";

const ModalBody = () => {
  const { content } = useModalContext();

  return <>{content}</>;
};

export default ModalBody;

export default {
  SIGN_IN_BANNER:
    "https://firebasestorage.googleapis.com/v0/b/hw-cloud-locker-develop-38822.appspot.com/o/assets%2Fimages%2FSignIn%2Fsign_in_banner.png?alt=media",
  SIGN_IN_OTP_BANNER:
    "https://firebasestorage.googleapis.com/v0/b/hw-cloud-locker-develop-38822.appspot.com/o/assets%2Fimages%2FSignInOTP%2Fsign_in_otp_banner.png?alt=media",
  WELCOME_NEW_USER_BANNER:
    "https://firebasestorage.googleapis.com/v0/b/hw-cloud-locker-develop-38822.appspot.com/o/assets%2Fimages%2FWelcomeNewUser%2Fwelcome_new_user_banner.png?alt=media",
  SENDING_COMPLETE_BANNER: "/assets/lotties/delivery_complete_banner.json",
  AUTH_INDICATOR_BANNER: "/assets/lotties/auth_indicator_banner.json"
};

// components
import SignOutBody from "./mains/SignOutBody";
// ghosts
import CustomConfirmButton from "./ghosts/CustomConfirmButton";
// others
import styles from "./styles.module.less";

const SignOut = () => (
  <div className={styles.signOutContentWrapper}>
    <SignOutBody />
    <CustomConfirmButton />
  </div>
);

export default SignOut;

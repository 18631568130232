import screenNames from "./screenNames";
import screens from "./screens";
import messages from "./messages";
// constants
import CONSTANTS from "@/constants";

const { VI_VN, EN_US } = CONSTANTS.LOCALE;

export default {
  [EN_US]: { ...screenNames[EN_US], ...screens[EN_US], ...messages[EN_US] },
  [VI_VN]: { ...screenNames[VI_VN], ...screens[VI_VN], ...messages[VI_VN] }
};

// libs
import { PropsWithChildren } from "react";
// hooks
import { useAuthContext, useDelayRender, useMasterDataContext } from "@/hooks";
// components
import ContainerPreloadBanner from "./mains/ContainerPreloadBanner";

const ContainerPreload = ({ children }: PropsWithChildren) => {
  const isDelayed = useDelayRender(2000);
  const masterData = useMasterDataContext();
  const { user } = useAuthContext();

  const deferEffect = !(user && masterData) || isDelayed;

  if (deferEffect) return <ContainerPreloadBanner />;

  return <>{children}</>;
};

export default ContainerPreload;

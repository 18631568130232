const error = {
  COMMON_MSG_E_001: "Phone number is required",
  COMMON_MSG_E_002: "Full name is required",
  COMMON_MSG_E_003: "Invalid QR Code",
  COMMON_MSG_E_004: "Invalid phone number. Please try again.",
  COMMON_MSG_E_005: "Sorry, an error has occurred. Please try again."
};

export default {
  ...error
};

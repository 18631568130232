// libs
import { useState } from "react";
// types
import { DoGetReceiverName } from "@/types/screens/CabinetAssign";
// hooks
import { useLoading, useLocaleContext } from "@/hooks";
// components
import HeadingBlankCard from "@/components/HeadingBlankCard";
import ReceiverPhoneNo from "../../components/ReceiverPhoneNo";
import ReceiverName from "../../components/ReceiverName";
// ghosts
import GetReceiverName from "../../ghosts/GetReceiverName";
// others
import { UserOperation } from "@/serverless";

const ReceiverSection = () => {
  const [triggerGetReceiverName, setTriggerGetReceiverName] = useState(0);
  const { localeDataSource } = useLocaleContext();
  const [doGetReceiverName, gettingReceiverName] =
    useLoading<DoGetReceiverName>(UserOperation.getDisplayName);

  return (
    <HeadingBlankCard
      iconName="mdi-smart-card-outline"
      headingTitle={localeDataSource["CabinetAssign.receiver.info.heading"]}
    >
      <ReceiverPhoneNo
        {...{ gettingReceiverName, setTriggerGetReceiverName }}
      />
      <ReceiverName />
      <GetReceiverName
        {...{ triggerGetReceiverName, gettingReceiverName, doGetReceiverName }}
      />
    </HeadingBlankCard>
  );
};
export default ReceiverSection;

// others
import CONSTANTS from "@/constants";
import {
  LockerMixin,
  CabinetMixin,
  RentalMixin,
  MasterMixin,
  UserMixin
} from "./mixins";

const { LOCKERS, CABINETS, RENTALS, MASTERS, USERS } = CONSTANTS.COLLECTIONS;

export class LockerOperation extends LockerMixin {
  constructor({ lockerId }: Record<string, string>) {
    const collectionPath = LOCKERS;
    const docPath = `${collectionPath}/${lockerId}`;

    super({ docPath });
  }
}

export class CabinetOperation extends CabinetMixin {
  constructor({ lockerId, cabinetNo }: Record<string, string>) {
    const collectionPath = `${LOCKERS}/${lockerId}/${CABINETS}`;
    const docPath = `${collectionPath}/${cabinetNo}`;

    super({ collectionPath, docPath });
  }
}

export class RentalOperation extends RentalMixin {
  constructor({ lockerId }: Record<string, string>) {
    const collectionPath = `${LOCKERS}/${lockerId}/${RENTALS}`;

    super({ collectionPath });
  }
}

export class MasterOperation extends MasterMixin {
  constructor() {
    const collectionPath = MASTERS;
    const docPath = `${collectionPath}/all`;

    super({ docPath });
  }
}

export class UserOperation extends UserMixin {
  constructor({ uid }: Record<string, string>) {
    const collectionPath = USERS;
    const docPath = `${collectionPath}/${uid}`;

    super({ docPath });
  }
}

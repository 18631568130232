export default {
  "Common.confirm.text": "Đồng ý",
  "Common.cancel.text": "Hủy",
  "Common.enter.full.name.placeholder": "NHẬP HỌ TÊN",
  "Common.back.home.text": "Về trang chủ",
  "Common.complete.subhead":
    "Cảm ơn bạn đã sử dụng dịch vụ CloudLocker.\nChúc bạn có những trải nghiệm tuyệt vời!",
  "Common.close.door.warning":
    "Bạn vui lòng đóng cửa ô tủ sau khi\nhoàn tất thao tác",
  "Common.step.label.1": "Bước 1:",
  "Common.step.label.2": "Bước 2:",
  "Common.step.label.3": "Bước 3:",
  "Common.step.content.1": "Quan sát ô tủ được mở",
  "Common.step.content.2.deposit.pickup": "Đặt vào hoặc lấy kiện hàng ra",
  "Common.step.content.3": "Đóng cửa ô tủ",
  "Common.confirm.modal.ok": "Đồng ý",
  "Common.confirm.modal.cancel": "Hủy",
  "Common.parcel.not.fit": "Kiện hàng không vừa tủ?",
  "Common.parcel.not.fit.confirm.message":
    "Bạn muốn thay đổi kích thước tủ?\nNhấn “Đồng ý” để hủy bỏ quá trình này.\nSau đó, quét lại mã QR để bắt đầu lượt thuê mới.",
  "Common.parcel.not.fit.warning":
    "Bạn vui lòng đóng cửa ô tủ\nđể hoàn tất thao tác",
  "Common.next.step.text": "Tiếp theo",
  "Common.process.indicator": "Đang tải..."
};

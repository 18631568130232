// libs
import { Dialog, DialogConfirmProps } from "antd-mobile";
// hooks
import useLocaleContext from "./useLocaleContext";

const useShowConfirmModal = () => {
  const { localeDataSource } = useLocaleContext();

  const showConfirmModal = ({
    title = null,
    content = "",
    cancelText,
    confirmText,
    onConfirm
  }: DialogConfirmProps) =>
    Dialog.confirm({
      title,
      content,
      cancelText: cancelText || localeDataSource["Common.confirm.modal.cancel"],
      onConfirm,
      confirmText: confirmText || localeDataSource["Common.confirm.modal.ok"],
      bodyStyle: { whiteSpace: "pre-line" }
    });

  return { showConfirmModal };
};

export default useShowConfirmModal;

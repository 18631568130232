// libs
import { Swiper } from "antd-mobile";
// types
import { CabinetSize } from "@/types/screens/CabinetAssign";
// components
import CabinetSizeItem from "../CabinetSizeItem";
// others
import styles from "./styles.module.less";

const NoneDisplay = () => null;

const CabinetSizeList = ({ cabinetSizes }: { cabinetSizes: CabinetSize[] }) => (
  <Swiper
    slideSize={40}
    trackOffset={0}
    defaultIndex={0}
    className={styles.cabinetSizeListWrapper}
    indicator={NoneDisplay}
  >
    {cabinetSizes.map(({ width, height, vacancyQty, cabinetSize }) => (
      <Swiper.Item key={cabinetSize}>
        <CabinetSizeItem
          {...{
            width,
            height,
            vacancyQty,
            cabinetSize
          }}
        />
      </Swiper.Item>
    ))}
  </Swiper>
);

export default CabinetSizeList;

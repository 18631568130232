// others
import CONSTANTS from "@/constants";

const { VI_VN, EN_US } = CONSTANTS.LOCALE;

export const switchLocaleList = [
  {
    localeTitle: "Tiếng Việt",
    localeKey: VI_VN
  },
  {
    localeTitle: "English",
    localeKey: EN_US
  }
];

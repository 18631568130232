// components
import MatIcon from "@/components/MatIcon";
// others
import CONSTANTS from "@/constants";

const ParcelNotFitIcon = () => (
  <MatIcon
    name="mdi-package-up"
    size="60px"
    color={CONSTANTS.COLORS.AZURE_RADIANCE}
  />
);

export default ParcelNotFitIcon;

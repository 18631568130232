enum DOOR_STATUS {
  OPEN = "open",
  CLOSE = "close"
}

const doorStatusMapping = {
  [DOOR_STATUS.CLOSE]: "0",
  [DOOR_STATUS.OPEN]: "1",
  0: DOOR_STATUS.CLOSE,
  1: DOOR_STATUS.OPEN
} as const;

export default {
  DOOR_STATUS,
  doorStatusMapping
};

// hooks
import { useLocaleContext, useModalContext } from "@/hooks";
// components
import NextStepButton from "@/components/NextStepButton";
// others
import styles from "./styles.module.less";

const CancelButton = () => {
  const { localeDataSource } = useLocaleContext();
  const { onCancel } = useModalContext();

  return (
    <NextStepButton
      title={localeDataSource["Common.cancel.text"]}
      customStyle={styles.cancelButton}
      onClick={onCancel}
    />
  );
};

export default CancelButton;

// libs
import { createContext } from "react";
// types
import { AuthContextProvider, AuthContextValue } from "@/types/context";
// hooks
import { useProvideAuth } from "@/hooks";

export const AuthContext = createContext<AuthContextValue>(
  {} as AuthContextValue
);

export const ProvideAuth: AuthContextProvider = ({ value, children }) => {
  const auth = useProvideAuth();

  return (
    <AuthContext.Provider value={value || auth}>
      {children}
    </AuthContext.Provider>
  );
};

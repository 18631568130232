// components
import FirebaseVerifier from "@/components/FirebaseVerifier";
// hooks
import { useAuthContext, useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const GetVerificationId = ({
  triggerGetVerificationId,
  setRetryInterval
}: {
  triggerGetVerificationId: number;
  setRetryInterval: (resendInterval: number) => void;
}) => {
  const { getVerificationId } = useAuthContext();
  const router = useRouter();
  const { formattedPhoneNo } = router.state || {};

  // TODO: Parallel RetryInterval and FirebaseVerifierCallback
  const verifiedCallback = () => setRetryInterval(CONSTANTS.RESEND_INTERVAL);

  return (
    <FirebaseVerifier
      {...{
        formattedPhoneNo,
        getVerificationId,
        triggerGetVerificationId,
        verifiedCallback
      }}
    />
  );
};

export default GetVerificationId;

// hooks
import { useLocaleContext } from "@/hooks";
// components
import HeadingBlankCard from "@/components/HeadingBlankCard";
import RentalNote from "../../components/RentalNote";
import RentalNotice from "../../components/RentalNotice";

const NoteSection = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <HeadingBlankCard
      iconName="mdi-chat-processing-outline"
      headingTitle={localeDataSource["CabinetAssign.rental.note.heading"]}
    >
      <RentalNote />
      <RentalNotice />
    </HeadingBlankCard>
  );
};

export default NoteSection;

import LOCKERS from "./lockers";
import CABINETS from "./cabinets";
import RENTALS from "./rentals";
import USERS from "./users";

const COLLECTION_FIELD_NAMES = {
  LOCKERS,
  CABINETS,
  RENTALS,
  USERS
};

export default COLLECTION_FIELD_NAMES;

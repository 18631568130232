// components
import CabinetInfoItem from "../CabinetInfoItem";

const CabinetInfo = ({
  width,
  height,
  vacancyQty,
  isChosen
}: {
  width: string;
  height: string;
  vacancyQty: number;
  isChosen: boolean;
}) => {
  const cabinetInfo: { value: string | number; label: string }[] = [
    { value: width, label: "CabinetAssign.cabinet.width" },
    { value: height, label: "CabinetAssign.cabinet.height" },
    { value: vacancyQty, label: "CabinetAssign.vacancy.qty" }
  ];

  return (
    <>
      {cabinetInfo.map(({ value, label }) => (
        <CabinetInfoItem {...{ value, label, isChosen }} key={label} />
      ))}
    </>
  );
};

export default CabinetInfo;

// libs
import { Card } from "antd-mobile";
// components
import AppLogoBranding from "../../components/AppLogoBranding";
import SwitchLocaleControl from "@/components/Home/components/SwitchLocaleControl";
// others
import styles from "./styles.module.less";

const HomeHeader = () => (
  <Card bodyClassName={styles.homeHeaderWrapper}>
    <AppLogoBranding />
    <SwitchLocaleControl />
  </Card>
);

export default HomeHeader;

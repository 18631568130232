// components
import MatIcon from "@/components/MatIcon";
// others
import styles from "./styles.module.less";

const CardHeading = ({
  iconName,
  headingTitle
}: {
  iconName: string;
  headingTitle: string;
}) => (
  <div className={styles.cardHeadingWrapper}>
    <MatIcon name={iconName} />
    <p className={styles.headingTitle}>{headingTitle}</p>
  </div>
);

export default CardHeading;

// libs
import { useEffect } from "react";
// types
import { SetState } from "@/types/common";
// hooks
import { useWaitDoorOpen, useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { LOCKER_ID } = CONSTANTS.COLLECTION_FIELD_NAMES.RENTALS;

const ReceiveDoorOpenSignal = ({
  assignedCabinetNo,
  setShowOverlay,
  setTriggerRedirectProcess
}: {
  assignedCabinetNo: string;
  setShowOverlay: SetState<boolean>;
  setTriggerRedirectProcess: SetState<number>;
}) => {
  const router = useRouter();
  const { lockerInfo } = router.state || {};
  const { isOpened } = useWaitDoorOpen({
    cabinetNo: assignedCabinetNo,
    lockerId: lockerInfo[LOCKER_ID],
    setShowOverlay
  });

  useEffect(() => {
    if (!isOpened) return;

    setShowOverlay(false);

    setTriggerRedirectProcess(new Date().getTime());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return null;
};

export default ReceiveDoorOpenSignal;

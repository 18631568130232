// libs
import { Checkbox } from "antd-mobile";
// types
import { SetState } from "@/types/common";
// components
import MatIcon from "@/components/MatIcon";
// dataSources
import { countryCodes } from "@/dataSources/SwitchPrefix";
// others
import CONSTANTS from "@/constants";
import { formatSI } from "@/utils";
import styles from "./styles.module.less";

const { AZURE_RADIANCE } = CONSTANTS.COLORS;

const PrefixCheckboxes = ({
  selectedPrefix,
  setSelectedPrefix
}: {
  selectedPrefix: string;
  setSelectedPrefix: SetState<string>;
}) => (
  <>
    {countryCodes.map(({ countryCode, countryName }) => (
      <Checkbox
        key={countryCode}
        className={styles.prefixCheckBoxWrapper}
        checked={selectedPrefix === countryCode}
        onChange={() => {
          setSelectedPrefix(countryCode);
        }}
        icon={(checked: boolean) => (
          <MatIcon
            name={checked ? "mdi-radiobox-marked" : "mdi-radiobox-blank"}
            size="20"
            color={AZURE_RADIANCE}
          />
        )}
      >
        {formatSI("[countryName] ([countryCode])", {
          countryName,
          countryCode
        })}
      </Checkbox>
    ))}
  </>
);

export default PrefixCheckboxes;

// hooks
import { useLocaleContext, useSubmit } from "@/hooks";
// components
import NextStepButton from "@/components/NextStepButton";
// others
import styles from "./styles.module.less";

const GetStartedButton = ({
  updatingFirebaseProfile,
  updatingProfile
}: {
  updatingFirebaseProfile: boolean;
  updatingProfile: boolean;
}) => {
  const { localeDataSource } = useLocaleContext();
  const { doSubmit } = useSubmit();

  return (
    <NextStepButton
      title={localeDataSource["WelcomeNewUser.get.started"]}
      customStyle={styles.startButton}
      loading={updatingFirebaseProfile || updatingProfile}
      onClick={doSubmit}
    />
  );
};

export default GetStartedButton;

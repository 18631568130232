// libs
import { Formik } from "formik";
import { useState } from "react";
// types
import { DoGetVerificationId } from "@/types/screens/SignIn";
// hooks
import { useAuthContext, useLoading, useLocaleContext } from "@/hooks";
// components
import LockerHeaderTitle from "@/components/LockerHeaderTitle";
import StaticBanner from "@/components/StaticBanner";
import PhoneNo from "./mains/PhoneNo";
import SignInButton from "./mains/SignInButton";
// ghosts
import SwitchPrefixByRouter from "./ghosts/SwitchPrefixByRouter";
import GetVerificationId from "./ghosts/GetVerificationId";
// others
import CONSTANTS from "@/constants";
import signInFormikProps from "@/formiks/props/SignIn";
import styles from "./styles.module.less";

const { SIGN_IN_BANNER } = CONSTANTS.ASSET_LINKS;

const SignIn = () => {
  const { localeDataSource } = useLocaleContext();
  const [triggerGetVerificationId, setTriggerGetVerificationId] = useState(0);
  const { getVerificationId } = useAuthContext();
  const [doGetVerificationId, gettingVerificationId] =
    useLoading<DoGetVerificationId>(getVerificationId);

  const onSubmit = () => setTriggerGetVerificationId(new Date().getTime());

  return (
    <Formik {...signInFormikProps({ localeDataSource })} onSubmit={onSubmit}>
      <div className={styles.signInWrapper}>
        <LockerHeaderTitle />
        <div className={styles.signInWrapperInner}>
          <StaticBanner
            bannerSource={SIGN_IN_BANNER}
            headingTitle={localeDataSource["SignIn.sign.in.heading"]}
            subheadingTitle={localeDataSource["SignIn.sign.in.guide"]}
          />
          <PhoneNo />
          <SignInButton {...{ gettingVerificationId }} />
        </div>
        <SwitchPrefixByRouter />
        <GetVerificationId
          {...{
            triggerGetVerificationId,
            doGetVerificationId
          }}
        />
      </div>
    </Formik>
  );
};

export default SignIn;

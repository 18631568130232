// libs
import { useEffect } from "react";
// types
import { SetState } from "@/types/common";

const CountdownRetry = ({
  retryInterval,
  setRetryInterval
}: {
  retryInterval: number;
  setRetryInterval: SetState<number>;
}) => {
  useEffect(() => {
    const time = setTimeout(() => {
      setRetryInterval(retryInterval - 1);
    }, 1000);
    if (retryInterval === 0) {
      clearTimeout(time);
    }
    return () => clearTimeout(time);
  }, [retryInterval, setRetryInterval]);

  return null;
};

export default CountdownRetry;

// types
import { DoSendParcel } from "@/types/screens/SendingProcess";
// hooks
import { useLocaleContext, useRouter, useUpdateEffect } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import { showToastMessage } from "@/utils";

const { SENDING_COMPLETE } = CONSTANTS.ROUTERS;
const {
  LOCKER_ID,
  LOCKER_ADDRESS,
  LOCKER_NAME,
  LOCKER_NO,
  CABINET_NO,
  CABINET_SIZE,
  RECEIVER_NAME,
  RECEIVER_PHONE_NO,
  RENTAL_NOTE,
  SENDER_NAME,
  SENDER_PHONE_NO
} = CONSTANTS.COLLECTION_FIELD_NAMES.RENTALS;

const SendParcel = ({
  triggerSendParcel,
  doSendParcel
}: {
  triggerSendParcel: number;
  doSendParcel: DoSendParcel;
}) => {
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();
  const { rentalInfo } = router.state || {};

  useUpdateEffect(() => {
    if (!triggerSendParcel) return;

    (async () => {
      try {
        await doSendParcel({
          [LOCKER_ID]: rentalInfo[LOCKER_ID],
          [LOCKER_ADDRESS]: rentalInfo[LOCKER_ADDRESS],
          [LOCKER_NAME]: rentalInfo[LOCKER_NAME],
          [LOCKER_NO]: rentalInfo[LOCKER_NO],
          [CABINET_NO]: rentalInfo[CABINET_NO],
          [CABINET_SIZE]: rentalInfo[CABINET_SIZE],
          [RECEIVER_NAME]: rentalInfo[RECEIVER_NAME],
          [RECEIVER_PHONE_NO]: rentalInfo[RECEIVER_PHONE_NO],
          [RENTAL_NOTE]: rentalInfo[RENTAL_NOTE],
          [SENDER_NAME]: rentalInfo[SENDER_NAME],
          [SENDER_PHONE_NO]: rentalInfo[SENDER_PHONE_NO]
        });

        router.replace(SENDING_COMPLETE);
      } catch (error) {
        showToastMessage(localeDataSource.COMMON_MSG_E_005);
        router.goHome();
      }
    })();
  }, [triggerSendParcel]);

  return null;
};

export default SendParcel;

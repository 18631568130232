// hooks
import {
  useLocaleContext,
  useModalContext,
  useCustomConfirmModal
} from "@/hooks";

const CustomConfirmButton = ({
  selectedLocale
}: {
  selectedLocale: string;
}) => {
  const { setLocale } = useLocaleContext();
  const { onCancel } = useModalContext();

  useCustomConfirmModal(() => {
    setLocale(selectedLocale);
    onCancel();
  }, [selectedLocale]);

  return null;
};

export default CustomConfirmButton;

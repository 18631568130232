// components
import PhoneNoInput from "../../components/PhoneNoInput";
import PhoneNoPrefix from "../../components/PhoneNoPrefix";
// others
import styles from "./styles.module.less";

const PhoneNo = () => (
  <div className={styles.phoneNoWrapper}>
    <PhoneNoPrefix />
    <PhoneNoInput />
  </div>
);

export default PhoneNo;

// libs
import { Navigate } from "react-router-dom";
// hooks
import { useIsFirstRender } from "@/hooks";
// others
import CONSTANTS from "@/constants";

const { HOME } = CONSTANTS.ROUTERS;

const ForceRedirectHome = () => {
  const isFirstRender = useIsFirstRender();

  return isFirstRender ? <Navigate to={HOME} replace /> : null;
};

export default ForceRedirectHome;

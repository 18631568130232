enum FUNCTION_NAMES {
  UPDATE_PROFILE = "updateProfile",
  GET_DISPLAY_NAME = "getDisplayName",
  AUTO_ASSIGN_CABINET = "autoAssignCabinet",
  OPEN_CABINET = "openCabinet",
  SEND_PARCEL = "sendParcel",
  CANCEL_RENT_PROCESSING = "cancelRentProcessing"
}

export default FUNCTION_NAMES;

// hooks
import { useAuthorized } from "@/hooks";
import { Navigate } from "react-router-dom";
// components
import AuthHome from "../AuthHome";
import UnauthHome from "../UnauthHome";
// others
import CONSTANTS from "@/constants";

const { WELCOME_NEW_USER } = CONSTANTS.ROUTERS;

const Home = () => {
  const { notAuthenticatedUser, authenticatedNewUser } = useAuthorized();

  if (authenticatedNewUser) return <Navigate to={WELCOME_NEW_USER} />;
  if (notAuthenticatedUser) return <UnauthHome />;

  return <AuthHome />;
};

export default Home;

// libs
import { Button } from "antd-mobile";
import { Link, useLocation } from "react-router-dom";
// components
import MatIcon from "@/components/MatIcon";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { SIGN_OUT } = CONSTANTS.ROUTERS;

const LogOutButton = () => {
  const location = useLocation();

  return (
    <Link to={SIGN_OUT} state={{ background: location }}>
      <Button className={styles.logOutButtonWrapper} fill="solid">
        <MatIcon name="mdi-logout" />
      </Button>
    </Link>
  );
};

export default LogOutButton;

// types
import { GetDisplayName, UpdateProfile } from "@/types/serverless/mixins/users";
// serverless
import { DatabaseOperation, FunctionsOperation } from "../sdk";
// others
import CONSTANTS from "@/constants";

const { UPDATE_PROFILE, GET_DISPLAY_NAME } = CONSTANTS.FUNCTION_NAMES;

class UserMixin {
  databaseOpt: DatabaseOperation;

  /**
   * Compose DatabaseOperation
   */
  constructor({ docPath }: Record<string, string>) {
    this.databaseOpt = new DatabaseOperation({ docPath });
  }

  static updateProfile(values: UpdateProfile.Params) {
    return FunctionsOperation.callable<UpdateProfile.Params, null>(
      UPDATE_PROFILE
    )(values);
  }

  static async getDisplayName({ phoneNo }: GetDisplayName.Params) {
    const { data } = await FunctionsOperation.callable<
      GetDisplayName.Params,
      GetDisplayName.Result
    >(GET_DISPLAY_NAME)({
      phoneNo
    });

    const { displayName } = data;

    return displayName;
  }
}

export default UserMixin;

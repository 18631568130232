// libs
import classNames from "classnames";
import { Button, SpinLoading } from "antd-mobile";
// types
import { CallbackVoid } from "@/types/common";
// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const NextStepButton = ({
  title,
  disabled,
  onClick,
  customStyle,
  loadingText,
  loading = false
}: {
  title?: string;
  disabled?: boolean;
  onClick?: CallbackVoid;
  customStyle?: string;
  loadingText?: string;
  loading?: boolean;
}) => {
  const { localeDataSource } = useLocaleContext();

  return (
    <Button
      shape="rounded"
      color="primary"
      fill="solid"
      block
      loadingIcon={<SpinLoading color="white" />}
      className={classNames(styles.nextStepButtonWrapper, customStyle)}
      {...{ onClick, disabled, loading, loadingText }}
    >
      {title || localeDataSource["Common.next.step.text"]}
    </Button>
  );
};

export default NextStepButton;

// others
import styles from "./styles.module.less";

const ScanningRedLine = () => (
  <div className={styles.scanningRedLineWrapper}>
    <div className={styles.scanningRedLineWrapperInner} />
  </div>
);

export default ScanningRedLine;

// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const ServiceContent = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.serviceContentWrapper}>
      <div className={styles.serviceSlogan}>
        {localeDataSource["Home.slogan"]}
      </div>
      <div className={styles.serviceDownloadContent}>
        {localeDataSource["Home.download.content"]}
      </div>
    </div>
  );
};

export default ServiceContent;

// libs
import { QrcodeErrorCallback } from "html5-qrcode/esm/core";
// types
import { SetState } from "@/types/common";
// hooks
import useCameraPlugin from "@/hooks/useCameraPlugin";
// others
import CONSTANTS from "@/constants";
import styles from "./styles.module.less";

const { QRCODE_REGION_ID } = CONSTANTS.CAMERA_CONFIG;

const QrcodeScanner = ({
  setScanned,
  handleBarcodeScanned,
  handleScannedError
}: {
  setScanned: SetState<boolean>;
  handleBarcodeScanned: (data: string) => void;
  handleScannedError?: QrcodeErrorCallback;
}) => {
  useCameraPlugin({
    elementId: QRCODE_REGION_ID,
    setScanned,
    handleBarcodeScanned,
    handleScannedError
  });

  return <div id={QRCODE_REGION_ID} className={styles.qrCodeScannerWrapper} />;
};

export default QrcodeScanner;

// types
import { CabinetSize } from "@/types/screens/CabinetAssign";
// hooks
import { useLocaleContext } from "@/hooks";
// components
import HeadingBlankCard from "@/components/HeadingBlankCard";
import SelectSizeGuide from "../../components/SelectSizeGuide";
import CabinetSizeList from "../../components/CabinetSizeList";

const CabinetSizeSection = ({
  cabinetSizes
}: {
  cabinetSizes: CabinetSize[];
}) => {
  const { localeDataSource } = useLocaleContext();

  return (
    <HeadingBlankCard
      iconName="mdi-cube-outline"
      headingTitle={localeDataSource["CabinetAssign.cabinet.size.heading"]}
    >
      <SelectSizeGuide />
      <CabinetSizeList {...{ cabinetSizes }} />
    </HeadingBlankCard>
  );
};

export default CabinetSizeSection;

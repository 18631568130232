// libs
import { useEffect } from "react";
// types
import { Locker } from "@/types/firestore/lockers";
// hooks
import { useAuthorized, useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import { isNullObject } from "@/utils";

const { CABINET_ASSIGN, SIGN_IN } = CONSTANTS.ROUTERS;

const RedirectOperation = ({ lockerInfo }: { lockerInfo: Locker }) => {
  const router = useRouter();
  const { notAuthenticatedUser } = useAuthorized();

  useEffect(() => {
    if (isNullObject(lockerInfo)) return;

    const comingPath = notAuthenticatedUser ? SIGN_IN : CABINET_ASSIGN;

    router.replace(comingPath, {
      state: { lockerInfo }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lockerInfo]);

  return null;
};

export default RedirectOperation;

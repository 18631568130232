// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const SignOutBody = () => {
  const { localeDataSource } = useLocaleContext();

  return (
    <p className={styles.signOutText}>
      {localeDataSource["SignOut.confirm.message"]}
    </p>
  );
};

export default SignOutBody;

import CONSTANTS from "@/constants";

export const deliveryOperationList = [
  {
    label: "Home.sending",
    iconName: "mdi-cube-send",
    screenPath: CONSTANTS.ROUTERS.BARCODE_SCANNER
  },
  {
    label: "Home.receiving",
    iconName: "mdi-archive-arrow-down-outline",
    screenPath: "#" // TODO: change screen path
  }
];

// libs
import { useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLoading = <T extends (...args: any[]) => any>(action: T) => {
  const [loading, setLoading] = useState(false);

  const delayAction = (res: ReturnType<T>) =>
    new Promise((resolve) => {
      setTimeout(() => resolve(res), 500);
    });

  const doAction = (...args: Parameters<T>) => {
    setLoading(true);

    return action(...args)
      .then(delayAction)
      .finally(() => setLoading(false));
  };

  return [doAction, loading] as [T, boolean];
};

export default useLoading;

// libs
import { useFormikContext } from "formik";
// types
import { DoGetVerificationId } from "@/types/screens/SignIn";
// hooks
import { useRouter } from "@/hooks";
// components
import FirebaseVerifier from "@/components/FirebaseVerifier";
// others
import CONSTANTS from "@/constants";
import { formatPhoneNo } from "@/utils";

const { SIGN_IN_OTP } = CONSTANTS.ROUTERS;
const { PHONE_NO_PREFIX, PHONE_NO } = CONSTANTS.SCREEN_FIELD_NAMES.SIGN_IN;

const GetVerificationId = ({
  triggerGetVerificationId,
  doGetVerificationId
}: {
  triggerGetVerificationId: number;
  doGetVerificationId: DoGetVerificationId;
}) => {
  const router = useRouter();
  const { lockerInfo } = router.state || {};
  const { values } = useFormikContext<Record<string, string>>();

  const formattedPhoneNo = formatPhoneNo(
    values[PHONE_NO],
    values[PHONE_NO_PREFIX]
  );

  const verifiedCallback = (verificationId: string) =>
    router.replace(SIGN_IN_OTP, {
      state: {
        verificationId,
        formattedPhoneNo,
        lockerInfo
      }
    });

  return (
    <FirebaseVerifier
      {...{
        formattedPhoneNo,
        getVerificationId: doGetVerificationId,
        triggerGetVerificationId,
        verifiedCallback
      }}
    />
  );
};

export default GetVerificationId;

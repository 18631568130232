// libs
import { Button } from "antd-mobile";
// types
import { SetState } from "@/types/common";
// hooks
import { useLocaleContext } from "@/hooks";
// others
import styles from "./styles.module.less";

const ResendButton = ({
  setTriggerGetVerificationId
}: {
  setTriggerGetVerificationId: SetState<number>;
}) => {
  const { localeDataSource } = useLocaleContext();

  return (
    <div className={styles.resendButtonWrapper}>
      <Button
        className={styles.resendButtonWrapperInner}
        onClick={() => setTriggerGetVerificationId(new Date().getTime())}
      >
        <p>{localeDataSource["SignInOTP.resend.button"]}</p>
      </Button>
    </div>
  );
};

export default ResendButton;

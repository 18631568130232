// libs
import * as yup from "yup";
// others
import CONSTANTS from "@/constants";

const { PHONE_NO } = CONSTANTS.SCREEN_FIELD_NAMES.SIGN_IN;
const { PHONE_NO_MIN_LENGTH } = CONSTANTS.CODE_LENGTH;

export default ({
  localeDataSource
}: {
  localeDataSource: Record<string, string>;
}) =>
  yup.object().shape({
    [PHONE_NO]: yup
      .string()
      .required(localeDataSource.COMMON_MSG_E_001)
      .min(PHONE_NO_MIN_LENGTH - 1, localeDataSource.COMMON_MSG_E_004)
  });

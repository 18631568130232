import common from "./common";
import firebase from "./firebase";
import CabinetAssign from "./CabinetAssign";
import SignInOTP from "./SignInOTP";

export default {
  ...common,
  ...firebase,
  ...CabinetAssign,
  ...SignInOTP
};

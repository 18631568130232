// types
import { Locker } from "@/types/firestore/lockers";
// others
import CONSTANTS from "@/constants";
import { FirestoreOperation } from "../sdk";

const { LOCKER_ID } = CONSTANTS.COLLECTION_FIELD_NAMES.LOCKERS;

class LockerMixin {
  firestoreOpt: FirestoreOperation;

  static docId: string = LOCKER_ID;

  /**
   * Compose FirestoreOperation
   */
  constructor({ docPath }: Record<string, string>) {
    this.firestoreOpt = new FirestoreOperation({
      docId: LockerMixin.docId,
      docPath
    });
  }

  getLocker() {
    const { getDocument } = this.firestoreOpt.provideQuery();

    return getDocument<Locker>();
  }
}

export default LockerMixin;

// libs
import { useFormikContext } from "formik";
import { useEffect } from "react";
// types
import { SetState } from "@/types/common";
import { CabinetAssignValues } from "@/types/screens/CabinetAssign";
// hooks
import { useLocaleContext, useRouter } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import { CabinetOperation } from "@/serverless";
import { showToastMessage } from "@/utils";

const { LOCKER_ID, CABINET_SIZE } = CONSTANTS.COLLECTION_FIELD_NAMES.RENTALS;

const AssignCabinet = ({
  triggerAssignCabinet,
  setAssignedCabinetNo,
  setShowOverlay,
  setTriggerOpenCabinet
}: {
  triggerAssignCabinet: number;
  setAssignedCabinetNo: SetState<string>;
  setShowOverlay: SetState<boolean>;
  setTriggerOpenCabinet: SetState<number>;
}) => {
  const { values } = useFormikContext<CabinetAssignValues>();
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();
  const { lockerInfo } = router.state || {};

  useEffect(() => {
    if (!triggerAssignCabinet) return;

    (async () => {
      try {
        setShowOverlay(true);

        const cabinetNo = await CabinetOperation.autoAssign({
          [LOCKER_ID]: lockerInfo[LOCKER_ID],
          [CABINET_SIZE]: values[CABINET_SIZE]
        });

        setAssignedCabinetNo(cabinetNo);
        setTriggerOpenCabinet(new Date().getTime());
      } catch {
        setShowOverlay(false);
        showToastMessage(localeDataSource.COMMON_MSG_E_005);
        router.goHome();
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerAssignCabinet]);

  return null;
};

export default AssignCabinet;

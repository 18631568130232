// libs
import { useRouter } from "@/hooks";
import { Route, Routes } from "react-router-dom";
// routes
import appStackScreens from "../appStackScreens";

const AppRoutes = () => {
  const router = useRouter();
  const { location, state } = router;

  return (
    <Routes location={state?.background || location}>
      {appStackScreens.map(({ name, element: Element, path }) => (
        <Route key={name} element={<Element />} path={path} />
      ))}
    </Routes>
  );
};

export default AppRoutes;

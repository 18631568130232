// hooks
import { useLocaleContext, useSubmit } from "@/hooks";
// components
import NextStepButton from "@/components/NextStepButton";
// others
import styles from "./styles.module.less";

const SendingButton = () => {
  const { localeDataSource } = useLocaleContext();
  const { doConfirmSubmit } = useSubmit();

  return (
    <NextStepButton
      customStyle={styles.sendingButtonWrapper}
      title={localeDataSource["CabinetAssign.send"]}
      onClick={() => doConfirmSubmit(localeDataSource.CABINET_ASSIGN_MSG_I_001)}
    />
  );
};

export default SendingButton;

// libs
import { useState, useEffect } from "react";
import {
  ApplicationVerifier,
  onAuthStateChanged,
  PhoneAuthProvider,
  signInWithCredential,
  signOut,
  User
} from "firebase/auth";
// others
import { FIREBASE_AUTH } from "@/firebaseConfig";

const useProvideAuth = () => {
  const [user, setUser] = useState<User>();

  const signin = async (verificationId: string, verificationCode: string) => {
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const response = await signInWithCredential(FIREBASE_AUTH, credential);
    setUser(response.user);

    return response.user;
  };

  const getVerificationId = async (
    phoneNo: string,
    reCaptChaVerifier: ApplicationVerifier
  ) => {
    const phoneProvider = new PhoneAuthProvider(FIREBASE_AUTH);
    const verificationId = await phoneProvider.verifyPhoneNumber(
      phoneNo,
      reCaptChaVerifier
    );

    return verificationId;
  };

  const signout = async () => {
    await signOut(FIREBASE_AUTH);
    setUser({} as User);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FIREBASE_AUTH, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        return;
      }
      setUser({} as User);
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return { user, signin, getVerificationId, signout };
};

export default useProvideAuth;

// libs
import { Card } from "antd-mobile";
// components
import UserInfo from "../../components/UserInfo";
import ControlButtons from "../ControlButtons";
// others
import styles from "./styles.module.less";

const UserGreeting = () => (
  <Card bodyClassName={styles.userGreetingWrapper}>
    <UserInfo />
    <ControlButtons />
  </Card>
);

export default UserGreeting;

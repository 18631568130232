// libs
import { useState } from "react";
import { Formik } from "formik";
import { updateProfile as updateFirebaseProfile } from "firebase/auth";
// types
import {
  DoUpdateFirebaseProfile,
  DoUpdateProfile
} from "@/types/screens/WelcomeNewUser";
// hooks
import { useLoading, useLocaleContext } from "@/hooks";
// components
import StaticBanner from "@/components/StaticBanner";
import DisplayNameInput from "./mains/DisplayNameInput";
import GetStartedButton from "./mains/GetStartedButton";
// ghosts
import MakeUserExist from "./ghosts/MakeUserExist";
import RedirectOperation from "./ghosts/RedirectOperation";
// others
import CONSTANTS from "@/constants";
import welcomeNewUserFormikProps from "@/formiks/props/WelcomeNewUser";
import { UserOperation } from "@/serverless";
import styles from "./styles.module.less";

const WelcomeNewUser = () => {
  const { localeDataSource } = useLocaleContext();
  const [triggerMakeUserExist, setTriggerMakeUserExist] = useState<number>(0);
  const [triggerRedirect, setTriggerRedirect] = useState<number>(0);
  const [doUpdateFirebaseProfile, updatingFirebaseProfile] =
    useLoading<DoUpdateFirebaseProfile>(updateFirebaseProfile);
  const [doUpdateProfile, updatingProfile] = useLoading<DoUpdateProfile>(
    UserOperation.updateProfile
  );

  const onSubmit = () => setTriggerMakeUserExist(new Date().getTime());

  return (
    <Formik
      {...welcomeNewUserFormikProps({ localeDataSource })}
      onSubmit={onSubmit}
    >
      <div className={styles.welcomeNewUserWrapper}>
        <StaticBanner
          bannerSource={CONSTANTS.ASSET_LINKS.WELCOME_NEW_USER_BANNER}
          headingTitle={localeDataSource["WelcomeNewUser.heading"]}
          subheadingTitle={localeDataSource["WelcomeNewUser.subheading"]}
        />
        <DisplayNameInput />
        <GetStartedButton {...{ updatingFirebaseProfile, updatingProfile }} />
        <MakeUserExist
          {...{
            triggerMakeUserExist,
            doUpdateFirebaseProfile,
            doUpdateProfile,
            setTriggerRedirect
          }}
        />
        <RedirectOperation {...{ triggerRedirect }} />
      </div>
    </Formik>
  );
};

export default WelcomeNewUser;

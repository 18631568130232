// libs
import { Modal } from "antd-mobile";
import type { PropsWithChildren, ReactNode } from "react";
// hooks
import { useProvideModal } from "@/hooks";
// components
import ModalContent from "./mains/ModalContent";
// others
import { ProvideModal } from "@/contexts/ProvideModal";

const CustomModal = ({
  children,
  isVisible,
  showFooter = true,
  title
}: PropsWithChildren<{
  isVisible: boolean;
  showFooter?: boolean;
  title?: ReactNode;
}>) => {
  const { onCancel, setOnCancel, onOk, setOnOk } = useProvideModal();

  return (
    <ProvideModal
      value={{
        showFooter,
        content: children,
        setOnCancel,
        onCancel,
        onOk,
        setOnOk
      }}
    >
      <Modal
        {...{
          title,
          visible: isVisible,
          closeOnMaskClick: true,
          onClose: onCancel,
          content: <ModalContent />
        }}
      />
    </ProvideModal>
  );
};

export default CustomModal;

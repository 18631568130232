const error = {
  COMMON_MSG_E_001: "Số điện thoại là bắt buộc",
  COMMON_MSG_E_002: "Vui lòng nhập họ tên",
  COMMON_MSG_E_003: "Mã QR không hợp lệ",
  COMMON_MSG_E_004: "Số điện thoại không hợp lệ. Bạn vui lòng thử lại.",
  COMMON_MSG_E_005: "Rất tiếc, đã xảy ra lỗi. Bạn vui lòng thử lại."
};

export default {
  ...error
};

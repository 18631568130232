// libs
import { createContext } from "react";
// types
import { ModalContextProvider, ModalContextValue } from "@/types/context";

export const ModalContext = createContext<ModalContextValue>(
  {} as ModalContextValue
);

export const ProvideModal: ModalContextProvider = ({ value, children }) => (
  <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
);

// libs
import { Route, Routes } from "react-router-dom";
// components
import MainPreLoad from "@/screens/MainPreLoad";
// routes
import mainStackScreens from "../mainStackScreens";

const MainRoutes = () => (
  <MainPreLoad>
    <Routes>
      {mainStackScreens.map(({ name, element: Element, path }) => (
        <Route key={name} element={<Element />} path={path} />
      ))}
    </Routes>
  </MainPreLoad>
);

export default MainRoutes;

// libs
import { DependencyList, useEffect } from "react";
// types
import { CallbackVoid } from "@/types/common";
// hooks
import useModalContext from "./useModalContext";

const useCustomCancelModal = (
  onCancel: CallbackVoid,
  deps: DependencyList = []
) => {
  const { setOnCancel } = useModalContext();

  useEffect(() => {
    setOnCancel(() => onCancel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return null;
};

export default useCustomCancelModal;

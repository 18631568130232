const error = {
  CABINET_ASSIGN_MSG_E_001: "Please choose your cabinet size.",
  CABINET_ASSIGN_MSG_E_002:
    "This mobile number does not belong to CloudLocker customer. Please try again.",
  CABINET_ASSIGN_MSG_E_003:
    "You cannot send parcel to yourself. Please try again."
};

const info = {
  CABINET_ASSIGN_MSG_I_001: "Are you sure you want\nto send?"
};

export default {
  ...error,
  ...info
};

// libs
import PhoneNoFormatter from "@/components/PhoneNoFormatter";
// hooks
import { useLocaleContext, useRouter } from "@/hooks";
// others
import styles from "./styles.module.less";

const PhoneNo = () => {
  const { localeDataSource } = useLocaleContext();
  const router = useRouter();
  const { formattedPhoneNo } = router.state || {};

  return (
    <div className={styles.phoneNoWrapper}>
      <PhoneNoFormatter
        prefix={localeDataSource["SignInOTP.please.input.phoneNo.subHeading"]}
        phoneNo={formattedPhoneNo}
      />
    </div>
  );
};

export default PhoneNo;

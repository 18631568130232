// libs
import { useEffect } from "react";
//types
import { DoCancelRentProcessing } from "@/types/screens/SendingProcess";
// hooks
import { useRouter, useLocaleContext } from "@/hooks";
// others
import CONSTANTS from "@/constants";
import { showToastMessage } from "@/utils";

const {
  LOCKERS: { LOCKER_ID },
  RENTALS: { CABINET_NO }
} = CONSTANTS.COLLECTION_FIELD_NAMES;

const CancelRentProcessing = ({
  triggerCancelRental,
  doCancelRentProcessing
}: {
  triggerCancelRental: number;
  doCancelRentProcessing: DoCancelRentProcessing;
}) => {
  const router = useRouter();
  const { rentalInfo } = router.state || {};
  const { localeDataSource } = useLocaleContext();

  useEffect(() => {
    if (!triggerCancelRental) return;

    (async () => {
      try {
        await doCancelRentProcessing({
          [LOCKER_ID]: rentalInfo[LOCKER_ID],
          [CABINET_NO]: rentalInfo[CABINET_NO]
        });
      } catch (error) {
        showToastMessage(localeDataSource.COMMON_MSG_E_005);
      }

      router.goHome();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerCancelRental]);

  return null;
};

export default CancelRentProcessing;

// components
import MatIcon from "../MatIcon";
// others
import styles from "./styles.module.less";

const AlertMessage = ({ message }: { message: string }) => (
  <div className={styles.alertMessageWrapper}>
    <MatIcon name="mdi-information" />
    <p className={styles.alertContent}>{message}</p>
  </div>
);

export default AlertMessage;

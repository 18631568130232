// libs
import * as yup from "yup";
// others
import CONSTANTS from "@/constants";

const { DISPLAY_NAME } = CONSTANTS.SCREEN_FIELD_NAMES.WELCOME_NEW_USER;

export default ({
  localeDataSource
}: {
  localeDataSource: Record<string, string>;
}) =>
  yup.object({
    [DISPLAY_NAME]: yup.string().required(localeDataSource.COMMON_MSG_E_002)
  });

// types
import { SetState } from "@/types/common";
// hooks
import { useRouter, useLocaleContext } from "@/hooks";
// components
import CustomModal from "@/components/CustomModal";
import NextStepButton from "@/components/NextStepButton";
// others
import styles from "./styles.module.less";

const StockoutAlert = ({
  isStockout,
  setIsStockout
}: {
  isStockout: boolean;
  setIsStockout: SetState<boolean>;
}) => {
  const router = useRouter();
  const { localeDataSource } = useLocaleContext();

  return (
    <CustomModal
      title={localeDataSource["CabinetAssign.no.empty.cabinets"]}
      isVisible={isStockout}
      showFooter={false}
    >
      <div className={styles.comeBackLaterText}>
        {localeDataSource["CabinetAssign.come.back.later"]}
      </div>
      <NextStepButton
        title={localeDataSource["Common.back.home.text"]}
        customStyle={styles.returnHomeButton}
        onClick={() => {
          setIsStockout(false);
          router.goHome();
        }}
      />
    </CustomModal>
  );
};

export default StockoutAlert;

// hooks
import {
  useAuthContext,
  useModalContext,
  useCustomConfirmModal
} from "@/hooks";

const CustomConfirmButton = () => {
  const { onCancel } = useModalContext();
  const { signout } = useAuthContext();

  useCustomConfirmModal(() => {
    signout();
    onCancel();
  }, []);

  return null;
};

export default CustomConfirmButton;
